import React from 'react';
import PropTypes from 'prop-types';
import CustomScreenComponentTile from '../../../../common/CustomScreenComponentTile';
import CustomScreenComponentText from '../../../../common/CustomScreenComponentText';
import { COMPONENT_TYPE_TEXT, COMPONENT_TYPE_TILE, COMPONENT_TYPE_VIDEO } from '../../../../common/componentTypes';
import CustomScreenGridLayout from '../../../../common/CustomScreenGridLayout';
import CustomScreenGridLayoutItem from '../../../../common/CustomScreenGridLayoutItem';
import CustomScreenComponentVideo from '../../../../common/CustomScreenComponentVideo';

const propTypes = {
  components: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    link: PropTypes.string,
    youtube_id: PropTypes.string,
  })).isRequired,
  areCookiesAllowed: PropTypes.bool.isRequired,
};

const renderComponent = (component, areCookiesAllowed) => {
  const { component_type: type } = component;
  switch (type) {
  case COMPONENT_TYPE_TILE:
    return <CustomScreenComponentTile component={component} />;
  case COMPONENT_TYPE_TEXT:
    return <CustomScreenComponentText component={component} />;
  case COMPONENT_TYPE_VIDEO:
    return <CustomScreenComponentVideo component={component} areCookiesAllowed={areCookiesAllowed} />;
  default:
    console.warn('Component type not found', type);
    return null;
  }
};

const CustomScreenMixedSection = ({ components, areCookiesAllowed }) => {
  return (
    <CustomScreenGridLayout>
      {components.map((c, i) => {
        // If content is not localized, hide the item completely
        const hasContent = c.image || c.title || c.body || c.youtube_id;
        if (!hasContent) {
          return null;
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <CustomScreenGridLayoutItem size={c.size} fill={c.fill_background} key={String(c.id) + i}>
            {renderComponent(c, areCookiesAllowed)}
          </CustomScreenGridLayoutItem>
        );
      })}
    </CustomScreenGridLayout>
  );
};

CustomScreenMixedSection.propTypes = propTypes;

export default CustomScreenMixedSection;
