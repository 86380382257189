import React from 'react';
import PropTypes from 'prop-types';
import UploadIcon from '../../../../../assets/images/icons/upload-1.svg';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  numberOfItemsToShare: PropTypes.number.isRequired,
};

const PdfShareMenu = ({ handleClick, handleCancel, numberOfItemsToShare }) => {
  const className = `bottom-actions ${numberOfItemsToShare ? ' active' : ''}`;
  return (
    <div className={className}>
      <div className="bottom-actions-buttons">
        <button
          type="button"
          className="btn connect-button btn-primary btn-blue"
          onClick={handleClick}
        >
          <UploadIcon />
          {`Share ${numberOfItemsToShare} PDF${numberOfItemsToShare > 1 ? 's' : ''}`}
        </button>
        <button
          type="button"
          className="btn"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

PdfShareMenu.propTypes = propTypes;

export default PdfShareMenu;
