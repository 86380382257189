import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import Analytics from '../libs/Analytics';
import EditionConfig from '../config/EditionConfig';

const propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

const getUIComponentName = (pathname) => {
  const screenConfig = EditionConfig.get().screens.find((s) => {
    return s.path === pathname;
  });
  return screenConfig ? screenConfig.ui_component_name : 'Screen Config Not Found';
};

class RouteChangeListener extends React.Component {
  constructor(props) {
    super(props);

    // Listen for all router change events and log to analytics
    props.history.listen((location) => {
      const { pathname } = location;
      Analytics.page(pathname, { uiComponentName: getUIComponentName(pathname) });
    });

    // Capture the initial page load
    const { pathname } = props.location;
    Analytics.page(pathname, { uiComponentName: getUIComponentName(pathname) });
  }

  render() {
    return null;
  }
}

RouteChangeListener.propTypes = propTypes;
export default withRouter(RouteChangeListener);
