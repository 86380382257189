import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  children: PropTypes.node,
  className: PropTypes.string,
};

const defaultProps = {
  children: null,
  size: 'md',
  className: null,
};

function NewModal({
  children, isOpen, size, className,
}) {
  return (
    <Modal
      isOpen={isOpen}
      appElement={document.getElementById('main')}
      className={`new-modal ${size} ${className || ''}`}
      overlayClassName="new-modal-overlay"
      bodyOpenClassName="new-modal-body-open"
      htmlOpenClassName="new-modal-html-open"
    >{children}
    </Modal>
  );
}

NewModal.propTypes = propTypes;
NewModal.defaultProps = defaultProps;

export default NewModal;
