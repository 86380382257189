import PropTypes from 'prop-types';

const RenewalsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    unitCost: PropTypes.number,
    totalCost: PropTypes.number,
  }),
);

export default RenewalsPropType;
