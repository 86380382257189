import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import RouteNotFound from '../components/screens/RouteNotFound';
import Modal from '../components/Modal';
import Nav from '../components/Nav';
import RouteChangeListener from '../components/RouteChangeListener';
import EditionConfig from './EditionConfig';
import Console from '../libs/Console';
import DebugConsole from '../components/DebugConsole';
import Env from './Env';
import areCookiesAllowed from '../libs/areCookiesAllowed';
import { disableOlark, loadOlark } from '../libs/Olark';
import { disableSegment, loadSegment } from '../libs/Segment';
import { loadCss, loadJs } from '../libs/load';
import Analytics from '../libs/Analytics';
import ScreenLoader from '../components/ScreenLoader';

// The basename for the SPA is passed from Rails in the HTML tag
const basename = document.querySelector('html').getAttribute('data-spa-basename');

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choiceDisplayed: false,
      cookiesAllowed: areCookiesAllowed(),
      olarkLoaded: false,
    };
  }

  componentDidMount = () => {
    const config = EditionConfig.get();

    if (config.gdpr_compliance_enabled) {
      // Fetch cookie consent library files
      loadCss('./cookie-consent.css');
      loadJs('./cookie-consent.js');

      // Register handlers for cookie consent library
      const docElem = document.querySelector('html');
      docElem.addEventListener('jamf.cookieConsentAllow', () => {
        const { choiceDisplayed } = this.state;
        loadOlark();
        loadSegment();
        if (choiceDisplayed) Analytics.track('Cookie Consent Allowed');
        this.setState({
          choiceDisplayed: false,
          cookiesAllowed: true,
          olarkLoaded: true,
        });
      });
      docElem.addEventListener('jamf.cookieConsentDeny', () => {
        const { choiceDisplayed } = this.state;
        if (choiceDisplayed) Analytics.track('Cookie Consent Denied');
        disableOlark();
        disableSegment();
        this.setState({
          choiceDisplayed: false,
          cookiesAllowed: false,
          olarkLoaded: false,
        });
      });

      // This is fired when the UI for soliciting consent is _displayed_
      // (not when the library is loaded as might be implied)
      docElem.addEventListener('jamf.cookieConsentLoad', () => {
        this.setState({ choiceDisplayed: true });
        Analytics.track('Cookie Consent Choice Displayed');
      });
    }
  };

  render = () => {
    const { cookiesAllowed, olarkLoaded } = this.state;
    const config = EditionConfig.get();

    let defaultPath = config.default_path;
    if (!defaultPath) {
      Console.error('Default screen not configured.  Using "/".');
      defaultPath = '/';
    }

    return (
      <Router basename={basename}>
        <>
          <Modal />
          <RouteChangeListener />
          {/* Div100vh uses JS to work around iOS 100vh bugs */}
          <Div100vh className="main-ui">
            <Nav
              olarkLoaded={olarkLoaded}
              areCookiesAllowed={cookiesAllowed}
            />
            <Switch>
              <Redirect from="/" to={defaultPath} exact />
              {config.screens.map((screenConfig) => (
                <Route
                  key={screenConfig.path}
                  exact
                  path={screenConfig.path}
                  render={props => (
                    <main>
                      <ScreenLoader
                        partialScreenConfig={screenConfig}
                        routeProps={props}
                        cookiesAllowed={cookiesAllowed}
                      />
                    </main>
                  )}
                />
              ))}
              <Route component={RouteNotFound} />
            </Switch>
          </Div100vh>
          {Env.isDebugMode && <DebugConsole errors={Console.errorsForDebugConsole} />}
        </>
      </Router>
    );
  };
}

export default Root;

