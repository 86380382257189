/*
// Adapted from https://davidwalsh.name/javascript-loader
// Usage:  Load different file types with one callback
Promise.all([
  load.js('lib/highlighter.js'),
  load.js('lib/main.js'),
  load.css('lib/highlighter.css'),
  load.img('images/logo.png')
]).then(function() {
  console.log('Everything has loaded!');
}).catch(function() {
  console.log('Oh no, epic failure!');
});
*/

// Function which returns a function: https://davidwalsh.name/javascript-functions
const getLoader = (tag) => {
  return (url) => {
    // This promise will be used by Promise.all to determine success or failure
    return new Promise(((resolve, reject) => {
      const element = document.createElement(tag);
      let parent = 'body';
      let attr = 'src';

      // Important success and error for the promise
      element.onload = () => {
        resolve(url);
      };
      element.onerror = () => {
        reject(url);
      };

      // Need to set different attributes depending on tag type
      switch (tag) {
      case 'script':
        element.async = true;
        break;
      case 'link':
        element.type = 'text/css';
        element.rel = 'stylesheet';
        attr = 'href';
        parent = 'head';
        break;
      default:
        break;
      }

      // Inject into document to kick off loading
      element[attr] = url;
      document[parent].appendChild(element);
    }));
  };
};

export const loadCss = getLoader('link');
export const loadJs = getLoader('script');
