import {
  JAMF_NOW_MEDIUM, JAMF_NOW_STRONG, JAMF_NOW_WEAK, JAMF_PRO_EXTRA_STRONG,
  JAMF_PRO_HARD_REQUIREMENT, JAMF_PRO_MEDIUM, JAMF_PRO_STRONG, JAMF_PRO_WEAK,
} from './PRODUCT_CHOOSER_WEIGHTS';

const getProductChooserFields = (industryOptions) => {
  return [
    {
      id: 'industry',
      label: 'Which industry applies?',
      type: 'dropdown',
      value: '',
      location: 'customer2',
      options: industryOptions,
      getWeight: (value) => {
        switch (value) {
        case 'Banking':
        case 'Government':
          return 3;
        default:
          return 0;
        }
      },
    },
    {
      id: 'industryOther',
      label: 'Other industry',
      type: 'text',
      value: '',
      location: 'customer2',
      getWeight: () => {
        return 0;
      },
      secondary: true,
      hideWhen: (state) => {
        let hide = true;
        const field = state.fields.find(f => f.id === 'industry');
        if (field) hide = field.value !== 'Other';
        return hide;
      },
    },
    {
      id: 'healthCareDetails',
      label: 'Health Care Details',
      type: 'checkboxes',
      value: [],
      location: 'customer2',
      options: [
        { value: 'Patient Use', label: 'Patient Use' },
        { value: 'Care Provider Use', label: 'Care Provider Use' },
        { value: 'Clinical Communications', label: 'Clinical Communications' },
      ],
      getWeight: (value, state) => {
        const industryField = state.fields.find(f => f.id === 'industry');
        if (industryField.value !== 'Healthcare') return 0;
        let weight = 0;
        if (value.indexOf('Patient Use') !== -1) weight += JAMF_PRO_MEDIUM;
        if (value.indexOf('Care Provider Use') !== -1) weight += JAMF_PRO_MEDIUM;
        if (value.indexOf('Clinical Communications') !== -1) weight += 3;
        return weight;
      },
      secondary: true,
      hideWhen: (state) => {
        let hide = true;
        const field = state.fields.find(f => f.id === 'industry');
        if (field) hide = field.value !== 'Healthcare';
        return hide;
      },
    },
    {
      id: 'eduDetails',
      label: 'Education Details',
      type: 'checkboxes',
      value: [],
      location: 'customer2',
      options: [
        { value: 'School Information System', label: 'School Information System' },
        { value: 'Apple School Manager', label: 'Apple School Manager' },
      ],
      getWeight: (value, state) => {
        const industryField = state.fields.find(f => f.id === 'industry');
        if (industryField.value !== 'Education') return 0;
        let weight = 0;
        if (value.indexOf('School Information System') !== -1) weight += JAMF_PRO_HARD_REQUIREMENT;
        if (value.indexOf('Apple School Manager') !== -1) weight += JAMF_PRO_WEAK;
        return weight;
      },
      secondary: true,
      hideWhen: (state) => {
        let hide = true;
        const field = state.fields.find(f => f.id === 'industry');
        if (field) hide = field.value !== 'Education';
        return hide;
      },
    },
    {
      id: 'numberOfAppleDevices',
      label: 'How many Apple devices?',
      type: 'number',
      value: '',
      location: 'customer1',
      getWeight: (value) => {
        if (typeof value === 'string' && value.length) {
          const num = parseInt(value, 10);
          if (num <= 100) return JAMF_NOW_MEDIUM;
          if (num <= 250) return JAMF_NOW_MEDIUM;
          if (num <= 1000) return JAMF_PRO_MEDIUM;
          if (num <= 2500) return JAMF_PRO_STRONG;
          if (num > 2500) return JAMF_PRO_HARD_REQUIREMENT;
        }
        return 0;
      },
    },
    {
      id: 'usedMDMBefore',
      label: 'Do they have MDM experience?',
      type: 'yes-no',
      value: null,
      location: 'customer1',
      getWeight: (value) => {
        if (value === true) return JAMF_PRO_WEAK;
        if (value === false) return JAMF_NOW_STRONG;
        return 0;
      },
    },
    {
      id: 'hasITDepartment',
      label: 'Is there an IT department?',
      type: 'yes-no',
      value: null,
      location: 'customer3',
      getWeight: (value) => {
        if (value === false) return JAMF_NOW_STRONG;
        return 0;
      },
    },
    {
      id: 'ITDepartmentDetails',
      label: 'IT Dept. Details',
      type: 'checkboxes',
      value: [],
      location: 'customer3',
      options: [
        { value: 'One or two employees only', label: 'One or two employees only' },
        { value: 'IT distributed by region', label: 'IT distributed by region' },
        { value: 'Multiple levels of IT support', label: 'Multiple levels of IT support' },
      ],
      secondary: true,
      hideWhen: (state) => {
        let hide = true;
        const field = state.fields.find(f => f.id === 'hasITDepartment');
        if (field) hide = field.value !== true;
        return hide;
      },
      getWeight: (value, state) => {
        const hasItDepartmentField = state.fields.find(f => f.id === 'hasITDepartment');
        if (hasItDepartmentField.value !== true) return 0;
        let weight = 0;
        if (value.indexOf('One or two employees only') !== -1) weight += JAMF_NOW_WEAK;
        if (value.indexOf('IT distributed by region') !== -1) weight += JAMF_PRO_MEDIUM;
        if (value.indexOf('Multiple levels of IT support') !== -1) weight += JAMF_PRO_STRONG;
        return weight;
      },
    },
    {
      id: 'platformsManaged',
      label: 'Which platforms will be managed?',
      type: 'checkboxes',
      value: [],
      location: 'requirements1',
      options: [
        { value: 'macOS', label: 'macOS' },
        { value: 'iOS', label: 'iOS' },
        { value: 'tvOS', label: 'tvOS' },
      ],
      getWeight: (value) => {
        if (value.indexOf('tvOS') !== -1) return JAMF_PRO_HARD_REQUIREMENT;
        return 0;
      },
    },
    {
      id: 'requiresNonAppStoreApps',
      label: 'Are non-App-Store apps required?',
      type: 'yes-no',
      value: null,
      location: 'requirements2',
      getWeight: () => {
        // Relevant weighting comes from nonAppStoreDetails checkboxes
        return 0;
      },
    },
    {
      id: 'nonAppStoreDetails',
      label: 'Other app requirements',
      type: 'checkboxes',
      value: [],
      location: 'requirements2',
      options: [
        { value: 'Adobe Creative Suite', label: 'Adobe Creative Suite' },
        { value: 'Microsoft Office', label: 'Microsoft Office' },
        { value: 'Patch Management', label: 'Patch Management' },
        { value: 'Customized Software', label: 'Custom iOS Apps' },
      ],
      secondary: true,
      hideWhen: (state) => {
        let hide = true;
        const field = state.fields.find(f => f.id === 'requiresNonAppStoreApps');
        if (field) hide = field.value !== true;
        return hide;
      },
      getWeight: (value, state) => {
        const requiresNonAppStoreAppsField = state.fields.find(f => f.id === 'requiresNonAppStoreApps');
        if (requiresNonAppStoreAppsField.value !== true) return 0;
        let weight = 0;
        if (value.indexOf('Adobe Creative Suite') !== -1) weight += JAMF_PRO_MEDIUM;
        if (value.indexOf('Patch Management') !== -1) weight += JAMF_PRO_HARD_REQUIREMENT;
        if (value.indexOf('Custom iOS Apps') !== -1) weight += JAMF_PRO_STRONG;
        return weight;
      },
    },
    {
      id: 'strictSecurityOrCompliance',
      label: 'Are there strict security or compliance needs?',
      type: 'yes-no',
      value: null,
      location: 'requirements3',
      getWeight: (value) => {
        if (value === true) return JAMF_PRO_MEDIUM;
        if (value === false) return JAMF_NOW_MEDIUM;
        return 0;
      },
    },
    {
      id: 'usesDirectoryServices',
      label: 'Do they use Directory Services?',
      type: 'yes-no',
      value: null,
      location: 'requirements3',
      getWeight: (value) => {
        if (value === true) return JAMF_PRO_EXTRA_STRONG;
        if (value === false) return JAMF_NOW_MEDIUM;
        return 0;
      },
    },
  ];
};

export default getProductChooserFields;
