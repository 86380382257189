import PropTypes from 'prop-types';

const EventPropType = PropTypes.shape({
  id: PropTypes.number,
  displayName: PropTypes.string,
  unitCost: PropTypes.number,
  totalCost: PropTypes.number,
});

export default EventPropType;
