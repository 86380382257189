import React from 'react';
import PropTypes from 'prop-types';
import EventTableRowLoadMore from './EventTableRowLoadMore';
import EventTableRow from './EventTableRow';
import EventPropType from '../../../shared-prop-types/EventPropType';

const propTypes = {
  events: PropTypes.arrayOf(EventPropType).isRequired,
  eventsToShare: PropTypes.arrayOf(EventPropType).isRequired,
  loading: PropTypes.bool.isRequired,
  numEventsToDisplay: PropTypes.number.isRequired,
  numColumns: PropTypes.number.isRequired,
  handleLoadMoreClick: PropTypes.func.isRequired,
  handleShareCheckboxClick: PropTypes.func.isRequired,
};

const EventTableBody = ({
  events, numEventsToDisplay, numColumns, eventsToShare, loading,
  handleLoadMoreClick, handleShareCheckboxClick,
}) => {
  const searchedAndSortedEventsInView = events.slice(0, numEventsToDisplay);

  // Loading
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan={numColumns}>
            <p>Loading latest events...</p>
          </td>
        </tr>
      </tbody>
    );
  }

  // Empty
  if (searchedAndSortedEventsInView.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={numColumns}>
            <p>Sorry, no events found.</p>
          </td>
        </tr>
      </tbody>
    );
  }

  // Display rows
  const rows = searchedAndSortedEventsInView.map((event) => {
    return (
      <EventTableRow
        handleShareCheckboxClick={handleShareCheckboxClick}
        event={event}
        key={event.id}
        isSelectedForSharing={eventsToShare.indexOf(event) !== -1}
      />
    );
  });

  if (events.length > searchedAndSortedEventsInView.length) {
    rows.push(
      <EventTableRowLoadMore
        numColumns={numColumns}
        handleClick={handleLoadMoreClick}
      />,
    );
  }

  return <tbody>{rows}</tbody>;
};
EventTableBody.propTypes = propTypes;

export default EventTableBody;
