export const JAMF_NOW_STRONG = -3;
export const JAMF_NOW_MEDIUM = -2;
export const JAMF_NOW_WEAK = -1;
export const JAMF_PRO_WEAK = 1;
export const JAMF_PRO_MEDIUM = 2;
export const JAMF_PRO_STRONG = 3;
export const JAMF_PRO_EXTRA_STRONG = 7;
export const JAMF_PRO_HARD_REQUIREMENT = 99;
export const JAMF_NOW_THRESHOLD = -5;
export const JAMF_PRO_THRESHOLD = 7;
