import React from 'react';
import PropTypes from 'prop-types';
import CustomScreenMixedSection from './CustomScreenMixedSection';

const propTypes = {
  screenConfig: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  areCookiesAllowed: PropTypes.bool.isRequired,
};

const CustomScreen = ({ screenConfig, areCookiesAllowed }) => {
  const { sections } = screenConfig;

  return (
    <div className="row">
      <div className="col-md-12">
        {sections.map(section => {
          const { id, components } = section;
          return (
            <div key={id} className="custom-screen-section">
              <CustomScreenMixedSection components={components} key={id} areCookiesAllowed={areCookiesAllowed} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

CustomScreen.propTypes = propTypes;

export default CustomScreen;
