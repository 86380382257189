import axios from 'axios';
import baseApiUrl from './baseApiUrl';

function sendEmail(data) {
  return new Promise((resolve, reject) => {
    axios.post(`${baseApiUrl}/mailer.json`, {
      contentType: 'application/json; charset=utf-8',
      body: JSON.stringify(data),
    }).then((response) => {
      const sendGridStatus = parseInt(response.status, 10);
      // The Rails API passes SendGrid responses (including errors) in 200 success responses
      // SendGrid SUCCESS
      if (sendGridStatus < 300) {
        resolve('success');
      // SendGrid ERROR
      // Reject with errors
      } else {
        reject(JSON.parse(response.data.body).errors);
      }
    }).catch((error) => {
      // Rails ERROR
      // (Sendgrid errors are handled above)
      reject(new Error(`Server error when sending email: ${error}`));
    });
  });
}

export default { sendEmail };
