/**
 * Determine if a component has content
 *
 * @param component an already-localized component
 * @returns {boolean}
 */
export const componentHasContent = (component) => {
  const { component_type: type } = component;

  switch(type) {
  case 'text':
    return !!(component.title || component.body);
  case 'tile':
    return !!(component.image || component.title || component.body);
  case 'video':
    return !!component.youtubeId;
  }
};
