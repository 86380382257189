import axios from 'axios';
import baseApiUrl from './baseApiUrl';

/**
 * Get Stores from the API.  Filter to specific countries if desired.
 *
 * @param [countryCodes] {string[]}
 * @returns {Promise<AxiosResponse<any>>}
 */

function get(countryCodes) {
  let countryQuery = '';
  if (countryCodes && countryCodes.length) {
    countryQuery = `?country_codes=${countryCodes.join(',')}`;
  }
  return axios.get(`${baseApiUrl}/stores.json${countryQuery}`);
}

export default { get };
