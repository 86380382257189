import React from 'react';
import ScreenConfigPropType from './ScreenConfigPropType';
import Tiles from '../../Tiles';

// Identical to PdfTiles with the exception of the screen-config-spec,
// which replaces instances of "pdf" with "link"
const LinkTiles = ({ screenConfig }) => {
  return (
    <Tiles screenConfig={screenConfig} />
  );
};

LinkTiles.propTypes = {
  screenConfig: ScreenConfigPropType.isRequired,
};

export default LinkTiles;
