import React from 'react';
import * as PropTypes from 'prop-types';
import EditionConfig from '../config/EditionConfig';
import ChatIcon from '../../../assets/images/icons/chat-3.svg';
import CancelIcon from '../../../assets/images/icons/cancel-circle.svg';
import Analytics from '../libs/Analytics';

const propTypes = {
  areCookiesAllowed: PropTypes.bool.isRequired,
  olarkLoaded: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

/* global olark */
class ChatToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatWindowOpen: false,
    };
  }

  // When olark is loaded, configure it and set event handlers
  componentDidUpdate = (prevProps) => {
    const { olarkLoaded } = this.props;
    if (olarkLoaded && !prevProps.olarkLoaded && window.olark) {
      const { chat } = EditionConfig.get();
      const { olark } = window;

      olark.configure('system.is_single_page_application', true);
      olark.configure('system.chat_does_not_follow_external_links', true);
      olark.configure('box.start_hidden', true);

      if (chat.operator_group_enabled) {
        olark.configure('system.group', chat.operator_group_id);
      }

      olark('api.box.onShrink', this.onShrink);
      olark('api.box.onHide', this.onHide);
      olark('api.box.onShow', this.onShow);
      olark('api.chat.onReady', this.onReady);

      olark('api.chat.onOperatorsAvailable', () => {
        Analytics.track('Chat Operators Available');
      });
      olark('api.chat.onOperatorsAway', () => {
        Analytics.track('Chat Operators Away');
      });
      olark('api.chat.onBeginConversation', () => {
        Analytics.track('Chat Conversation Begun');
      });
      olark('api.chat.onMessageToOperator', () => {
        Analytics.track('Chat Message Sent', { to: 'operator' });
      });
      olark('api.chat.onMessageToVisitor', () => {
        Analytics.track('Chat Message Sent', { to: 'visitor' });
      });
    }
    return null;
  };

  onShrink = () => {
    // Automatically hide when shrunk
    return window.setTimeout(() => {
      olark('api.box.hide');
    }, 500);
  };

  onShow = () => {
    // Olark tries to start with the window open, regardless of box.start_expanded setting
    // So DON'T follow-on this initial show command with the expand command we usually use
    this.setState({ chatWindowOpen: true });

    // Automatically expand when shown
    return window.setTimeout(() => {
      olark('api.box.expand');
    });
  };

  onHide = () => {
    return this.setState({ chatWindowOpen: false });
  };

  onReady = () => {
    this.setState({ chatWindowOpen: true });

    // Short-circuit silly open-window-on-load behavior
    olark('api.box.shrink');
    return olark('api.box.hide');
  };

  onClick = () => {
    const { areCookiesAllowed } = this.props;
    const { chatWindowOpen } = this.state;

    if (!areCookiesAllowed) return false;

    if (!chatWindowOpen) {
      // Showing it will also expand
      olark('api.box.show');
      Analytics.track('Chat Window Shown');
      return null;
    }

    Analytics.track('Chat Window Hidden');
    // Just shrink, the onShrink handler will actually close it
    return olark('api.box.shrink');
  };

  render = () => {
    const { areCookiesAllowed, olarkLoaded, label } = this.props;
    const { chatWindowOpen } = this.state;
    const enabledClass = areCookiesAllowed && olarkLoaded ? 'enabled' : 'hidden';
    const activeClass = chatWindowOpen ? 'active' : '';
    const currentLabel = chatWindowOpen ? 'Close chat' : label;
    const icon = chatWindowOpen ? <CancelIcon /> : <ChatIcon />;

    return (
      <li id="chat-toggle-item" className={`${enabledClass} ${activeClass}`}>
        <a id="chat-toggle" onClick={this.onClick}>{icon} {currentLabel}</a>
      </li>
    );
  };
}

ChatToggle.propTypes = propTypes;

export default ChatToggle;
