import React from 'react';
import * as PropTypes from 'prop-types';
import screenApi from '../api/legacyScreenApi';
import screenComponents from './screens';
import Console from '../libs/Console';
import screenConfigSpecs from '../config/screen-config-specs';
import ScreenConfig from '../config/ScreenConfig';
import customScreenApi from '../api/customScreenApi';
import { componentHasContent } from '../../common/componentHasContent';
import ScreenNoContentWarning from '../../common/ScreenNoContentWarning';
import EditionConfig from '../config/EditionConfig';

/**
 * Returns true if there is any content in any of the screen's components.
 *
 * @param sections
 * @returns {boolean}
 */
const screenHasNoContent = (sections) => {
  const components = sections.reduce((acc, s) => {
    acc = acc.concat(s.components);
    return acc;
  }, []);

  const hasAnyContent = components.reduce((acc, c) => {
    if (!acc && componentHasContent(c)){
      acc = true;
    }
    return acc;
  }, false);

  return !hasAnyContent;
};

const propTypes = {
  partialScreenConfig: PropTypes.shape({
    id: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['screen', 'custom_screen']).isRequired,
  }).isRequired,
  cookiesAllowed: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  routeProps: PropTypes.any.isRequired,
};

class ScreenLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false, error: null, ScreenComponent: null, screenConfig: null, screenHasNoContent: false,
    };
  }

  componentDidMount() {
    const { partialScreenConfig } = this.props;

    this.fetchScreen(partialScreenConfig.id, partialScreenConfig.type);
  }

  fetchScreen = (id, type) => {
    const handleError = error => {
      Console.error(error);
      this.setState({ error, loading: false });
    };

    const setScreenState = (screenConfig, componentName) => {
      const ScreenComponent = this.findScreenComponent(componentName);

      this.setState({
        loading: false,
        screenConfig,
        ScreenComponent,
      });

      // Scroll to targeted component once loaded
      if (componentName === 'CustomScreen' && window.location.hash) {
        setTimeout(() => {
          const target = document.querySelector(window.location.hash);

          if (target) {
            target.scrollIntoView({ behavior: 'smooth' });
            target.classList.add('target');
          }
        }, 500);
      }
    };

    this.setState({ loading: true });

    if (type === 'custom_screen') {
      customScreenApi.get(id)
        .then((response) => {
          if(screenHasNoContent(response.data.custom_screen.sections)) {
            this.setState({ loading: false, screenHasNoContent: true });
          } else {
            setScreenState(response.data.custom_screen, 'CustomScreen');
          }
        })
        .catch(handleError);
    } else {
      screenApi.get(id)
        .then((response) => {
          const { screen } = response.data;
          const { ui_component_name: componentName } = screen;
          const screenConfig = new ScreenConfig(screen, this.findScreenConfigSpec(componentName));

          setScreenState(screenConfig, componentName);
        })
        .catch(handleError);
    }
  };

  findScreenComponent = (componentName) => {
    const Component = screenComponents[componentName];
    if (!Component) {
      Console.error(`Screen component not found: ${componentName}`);
      return null;
    }
    return Component;
  };

  findScreenConfigSpec = (componentName) => {
    const screenConfigSpec = screenConfigSpecs[`${componentName}ScreenConfigSpec`];
    if (!screenConfigSpec) {
      Console.error(`Screen config spec not found for: ${componentName}.  Did you add it to screen-config-specs.js?`);
      return null;
    }
    return screenConfigSpec;
  };

  render() {
    const {
      error, loading, screenConfig, ScreenComponent, screenHasNoContent,
    } = this.state;
    const { cookiesAllowed, routeProps } = this.props;
    const ready = !error && !loading && ScreenComponent;
    const loadingMessage = null; // Loading is quick enough that we don't need to display anything
    const config = EditionConfig.get();

    if (screenHasNoContent) {
      return <ScreenNoContentWarning languageCode={config.current_language.code} />;
    }

    if (ready) {
      return (
        <ScreenComponent
          {...routeProps}
          areCookiesAllowed={cookiesAllowed}
          screenConfig={screenConfig}
        />
      );
    }

    if (error) {
      return error.message;
    }

    return loadingMessage;
  }
}

ScreenLoader.propTypes = propTypes;

export default ScreenLoader;
