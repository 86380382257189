import mailerApi from '../../../api/mailerApi';
import EditionConfig from '../../../config/EditionConfig';

function sendJamfNowGetStartedEmail(email, firstName, industry, templateId) {
  if (!templateId || templateId.length === 0) {
    return Promise.reject(new Error('Could not send followup email because no templateID was given.'));
  }

  return mailerApi.sendEmail({
    personalizations: [{
      to: [{ email }],
      dynamic_template_data: {
        firstName,
        industry,
        editionPathname: EditionConfig.get().pathname,
      },
    }],
    from: { email: EditionConfig.get().from_email_address },
    template_id: templateId,
  });
}

export default sendJamfNowGetStartedEmail;
