import React from 'react';
import PropTypes from 'prop-types';
import NoContentWarning from './NoContentWarning';
import ComponentTarget from "../frontend/components/ComponentTarget";
import {getComponentSlug} from "../frontend/libs/getComponentSlug";

const propTypes = {
  component: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    id: PropTypes.number,
    fill_background: PropTypes.bool,
  }).isRequired,
  devMode: PropTypes.bool,
};

const defaultProps = {
  devMode: false,
};

const CustomScreenComponentText = ({ component, devMode }) => {
  const {
    title, body, id, fill_background: fill,
  } = component;
  const hasContent = title || body;
  const noContentMsg = devMode
    ? <NoContentWarning />
    : null;
  const className = `custom-screen-mixed-component  
    text-component ${!hasContent ? ' incomplete' : ''} ${fill ? 'fill' : ''}`;
  const slug = getComponentSlug(component);

  return hasContent ? (
    <div key={id} id={slug} className={className}>
      <ComponentTarget slug={slug} devMode={devMode} />
      <h4>{title}</h4>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  ) : noContentMsg;
};

CustomScreenComponentText.propTypes = propTypes;
CustomScreenComponentText.defaultProps = defaultProps;

export default CustomScreenComponentText;
