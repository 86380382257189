import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Analytics from '../frontend/libs/Analytics';

// Assume links with a protocol ("//") are external, otherwise a screen route is intended
const SmartLink = ({
  href, children, devMode,
}) => {
  const external = href.indexOf('//') === -1;
  const onClick = () => {
    Analytics.track('Link Clicked', { href, external });
  };

  // In devMode render internal links as a simple anchor
  // Otherwise, render router Links
  const internalLink = devMode
    ? <a href={href} onClick={onClick}>{children}</a>
    : <Link to={href} onClick={onClick}>{children}</Link>;

  return (external ? internalLink
    : <a href={href} target="_blank" rel="noopener noreferrer" onClick={onClick}>{children}</a>
  );
};

SmartLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  devMode: PropTypes.bool,
};

SmartLink.defaultProps = {
  devMode: false,
};

export default SmartLink;
