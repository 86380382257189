import React from 'react';
import ScreenConfigPropType from './screens/LinkTiles/ScreenConfigPropType';
import SmartLink from '../../common/SmartLink';

const Tiles = ({ screenConfig }) => {
  const { resources } = screenConfig;
  let numberOfTiles = 0;
  let noTilesHavePoints = true;

  // Structure is a flat list of 15 sets of attributes
  const tileElems = [...Array(15).keys()].map((index) => {
    const numeral = index + 1;
    if (!resources[`tile_${numeral}_enabled`]) return null;

    const title = resources[`tile_${numeral}_title`];
    const point1 = resources[`tile_${numeral}_point_1`];
    const point2 = resources[`tile_${numeral}_point_2`];
    const point3 = resources[`tile_${numeral}_point_3`];
    const image = resources[`tile_${numeral}_image`];

    // This base component can handle "link" and "pdf" types
    const href = resources[`tile_${numeral}_link`] || resources[`tile_${numeral}_pdf`];
    numberOfTiles += 1;

    if (point1 || point2 || point3) noTilesHavePoints = false;

    return (
      <li key={title + href}>
        <SmartLink href={href}>
          <div className="image">
            <img alt="" src={image} />
          </div>
          {title ? <h4>{title}</h4> : null}
          <ul>
            {point1 ? <li>{point1}</li> : null}
            {point2 ? <li>{point2}</li> : null}
            {point3 ? <li>{point3}</li> : null}
          </ul>
        </SmartLink>
      </li>
    );
  });

  let tilesClassName = 'tiles';
  if (numberOfTiles === 4) {
    tilesClassName += ' has-four-tiles';
  }
  if (noTilesHavePoints) {
    tilesClassName += ' no-tiles-have-points';
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <ul className={tilesClassName}>
          {tileElems}
        </ul>
      </div>
    </div>
  );
};

Tiles.propTypes = {
  screenConfig: ScreenConfigPropType.isRequired,
};

export default Tiles;
