import React from 'react';
import PropTypes from 'prop-types';
import InternetIcon from '../../../../../assets/images/icons/internet.svg';
import InfoIcon from '../../../../../assets/images/icons/info.svg';
import EventShareCheckboxButton from './EventShareCheckboxButton';
import EventPropType from '../../../shared-prop-types/EventPropType';
import Analytics from '../../../libs/Analytics';
import getFlattenedEvent from './getFlattenedEvent';
import IconFlag from '../../IconFlag';

const propTypes = {
  event: EventPropType.isRequired,
  isSelectedForSharing: PropTypes.bool.isRequired,
  handleShareCheckboxClick: PropTypes.func.isRequired,
};

const EventTableRow = ({ event, isSelectedForSharing, handleShareCheckboxClick }) => {
  const localeIcons = [];
  if (event.locales.length === 0 || event.locales.length > 3) {
    localeIcons.push(<span key={event.id}><InternetIcon /></span>);
  } else {
    event.locales.forEach((localeCode) => {
      localeIcons.push(<IconFlag key={localeCode} countryCode={localeCode.slice(localeCode.length - 2)} />);
    });
  }

  // Older events may not have a product field
  const firstCell = (event.product && event.product.length)
    ? (
      <td className="fluid">
        <div className="connect-event-product">{event.product}:</div>
        <div className="connect-event-topic">{event.topic}</div>
      </td>
    )
    : (<td className="fluid">{event.topic}</td>);

  return (
    <tr key={event.id} className={isSelectedForSharing ? 'selected' : ''}>
      <td>
        <EventShareCheckboxButton handleClick={() => handleShareCheckboxClick(event)} />
      </td>
      {firstCell}
      <td>
        <div className="connect-event-date">{event.date}</div>
      </td>
      <td>
        <div className="connect-event-time">{event.time}</div>
        <div className="connect-event-localtime">{event.localTime} {event.timezone}</div>
      </td>
      <td>
        <div className="connect-event-locales">{localeIcons}</div>
      </td>
      <td>
        <a
          className="connect-button"
          href={event.url}
          target="_blank"
          rel="noopener noreferrer"
          title={event.type}
          onClick={() => Analytics.track('Event Info Link Clicked', getFlattenedEvent(event))}
        >
          <InfoIcon />
        </a>
      </td>
    </tr>
  );
};

EventTableRow.propTypes = propTypes;

export default EventTableRow;
