import React from 'react';
import EditionConfig from '../config/EditionConfig';
import ModalService from '../libs/ModalService';
import InternetIcon from '../../../assets/images/icons/internet.svg';
import IconFlag from './IconFlag';
import userLocale from '../libs/userLocale';

class LanguageToggle extends React.Component {

  onClick = () => {
    const config = EditionConfig.get();
    const content = (
      <>
        <h3>Select Preferred Language</h3>
        <ul className="language-toggle-grid">
          {config.supported_languages.map((lang) => {
            const { code } = lang;
            // From en-US to US
            const countryCode = code.slice(code.length - 2);
            return (
              <li key={code}>
                <button
                  type="button"
                  className={`btn ${config.current_language.code === code ? 'active' : ''}`}
                  onClick={() => { this.languageChanged(code); }}
                >
                  <IconFlag countryCode={countryCode} />
                  <strong>{lang.label}</strong>
                </button>
              </li>
            );
          })}
        </ul>
      </>
    );
    ModalService.display(content, 'modal-language-toggle', false, true, false);
  };

  languageChanged = (selectedLanguageCode) => {
    // Update user locale
    userLocale.set(undefined, selectedLanguageCode, true);
  };

  render = () => {
    const config = EditionConfig.get();
    return (
      <li className="language-toggle">
        <a onClick={this.onClick}>
          <InternetIcon />
          {config.current_language.label}
        </a>
      </li>
    );
  };
}

export default LanguageToggle;
