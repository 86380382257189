import React from 'react';
import * as PropTypes from 'prop-types';
import Analytics from '../../libs/Analytics';
import YouTubeEmbed from './YouTubeEmbed';
import getYouTubeThumbUrl from '../../../admin/libs/getYouTubeThumbUrl';

const propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({
    youtubeId: PropTypes.string.isRequired,
    caption: PropTypes.string,
  })).isRequired,
  showClosedCaptions: PropTypes.bool.isRequired,
  areCookiesAllowed: PropTypes.bool.isRequired,
};

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: props.videos.length ? props.videos[0].youtubeId : '',
    };
  }

  playVideo = (videoId) => {
    this.setState({ activeId: videoId });
  };

  getThumbItem = (videoId, imgSrc, caption, isActive) => (
    <li className={isActive ? 'active' : ''} key={videoId}>
      <button
        type="button"
        onClick={() => {
          this.playVideo(videoId);
          Analytics.track('Video Thumbnail Clicked', { videoId, caption });
        }}
      >
        <img src={imgSrc} alt={caption} />
        <span className="play" />
        <div className="caption">{caption}</div>
      </button>
    </li>
  );

  getThumbs = () => {
    const { videos } = this.props;
    const { activeId } = this.state;

    // No need for thumbs when there's only one video
    if (videos.length <= 1) return null;

    return (
      <ul className="thumbs">
        {videos.map(video => {
          const { youtubeId, caption } = video;
          const imgSrc = getYouTubeThumbUrl(youtubeId);
          const isActive = youtubeId === activeId;
          return this.getThumbItem(youtubeId, imgSrc, caption, isActive);
        })}
      </ul>
    );
  };

  render = () => {
    const { areCookiesAllowed, showClosedCaptions } = this.props;
    const { activeId } = this.state;

    const youtubeEmbed = areCookiesAllowed
      ? <YouTubeEmbed videoId={activeId} showClosedCaptions={showClosedCaptions} />
      : (
        <p>
          Sorry, the YouTube player uses tracking cookies which you have declined to allow.
        </p>
      );

    return (
      <div className="video-player">
        <div className="player">
          {youtubeEmbed}
        </div>
        {this.getThumbs()}
      </div>
    );
  };
}

VideoPlayer.propTypes = propTypes;

export default VideoPlayer;
