import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import * as PropTypes from 'prop-types';
import EditionConfig from '../config/EditionConfig';
import JamfLogo from '../../../assets/images/logo-jamf-white.svg';
import ChatToggle from './ChatToggle';
import NavIcons from '../libs/NavIcons';
import Console from '../libs/Console';
import LanguageToggle from './LanguageToggle';

/*
* @param navItem: {
*   screen_id: number,  // Either screen_id OR custom_screen_id should be present
*   custom_screen_id: number,
*   link_type: 'screen' | 'custom_screen',
* }
* */
const getPath = (navItem) => {
  const { screen_id: screenId, custom_screen_id: customScreenId, link_type: type } = navItem;
  const id = screenId || customScreenId;

  if (!id) return '';

  const config = EditionConfig.get();
  const screen = config.screens.find(s => s.id === id && s.type === type);

  if (!screen) {
    console.error('Screen not found for nav item: ', navItem);
    return '';
  }

  const { path } = screen;
  return path;
};

function getLink(navItem) {
  const icon = navItem.icon ? NavIcons[navItem.icon] : null;

  // External link
  if (navItem.link_type === 'external') {
    return (
      <a href={navItem.external_link} target="_blank" rel="noopener noreferrer">
        {icon}{navItem.display_name}
      </a>
    );
  }

  // Screen or custom screen
  return (
    <Link to={getPath(navItem)}>
      {icon}{navItem.display_name}
    </Link>
  );
}

const hasActiveChild = (children, activePathname) => {
  const childPaths = children.map((navItem) => {
    return getPath(navItem);
  });
  return childPaths.includes(activePathname);
};

const propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  areCookiesAllowed: PropTypes.bool.isRequired,
  olarkLoaded: PropTypes.bool.isRequired,
};

const Nav = ({ location, areCookiesAllowed, olarkLoaded }) => {
  const { pathname } = location;
  const config = EditionConfig.get();
  const { screens, chat, navigation } = config;

  if (screens.length === 0) {
    Console.error('No screens configured');
    return null;
  }

  // Hide the nav if there is only one screen
  if (screens.length === 1) {
    document.body.classList.add('hide-nav');
    return null;
  }

  return (
    <nav>
      <Link to="/" className="home">
        <JamfLogo className="logo" />
      </Link>
      <ul className="top">
        {navigation.sections.map((section) => {
          let items = [];
          items.push(
            <li className="heading" key={`section ${section.display_name}`}>
              {section.display_name}
            </li>,
          );
          items = items.concat(section.children.map((itemGroup) => {
            const activeChild = hasActiveChild(itemGroup.children, pathname);
            const isActive = pathname === getPath(itemGroup);
            return (
              <li
                key={`itemGroup ${itemGroup.display_name}`}
                className={isActive || activeChild ? 'active' : ''}
              >
                {getLink(itemGroup)}
                {itemGroup.children.length
                  ? (
                    <ul className={isActive || activeChild ? 'expanded' : ''}>
                      {itemGroup.children.map((item) => {
                        const itemIsActive = pathname === getPath(item);
                        return (
                          <li
                            key={`item ${item.display_name}`}
                            className={itemIsActive ? 'active' : ''}
                          >
                            {getLink(item)}
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
              </li>
            );
          }));
          return items;
        })}
      </ul>
      <ul className="bottom">
        <LanguageToggle />
        { chat.enabled
        && (
          <ChatToggle
            olarkLoaded={olarkLoaded}
            areCookiesAllowed={areCookiesAllowed}
            label={chat.label}
          />
        )}
      </ul>
    </nav>
  );
};

Nav.propTypes = propTypes;

export default withRouter(Nav);
