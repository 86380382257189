/* eslint-disable react/no-danger */
import React from 'react';
import ModalService from '../libs/ModalService';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      displayBlock: false,
      contentHtmlOrReactComponent: null,
      classToAdd: null,
      clickBackdropToClose: true,
      clickAnywhereToClose: false,
      onBackdropClick: null,
    };
  }

  componentDidMount() {
    ModalService.registerModalComponent(this);
  }

  hide = () => {
    document.body.classList.remove('modal-open');

    this.setState({
      open: false,
    });

    // Wait for exit transition before cleaning out added classes
    // And resetting remaining state
    window.setTimeout(() => {
      this.setState({
        open: false,
        displayBlock: false,
        contentHtmlOrReactComponent: null,
        classToAdd: null,
        clickBackdropToClose: true,
        clickAnywhereToClose: false,
        onBackdropClick: null,
      });
    }, 500);
  };

  // @param contentHtmlOrReactComponent: HTML string / React component to load in the modal
  // (required)
  // @param classToAdd: a CSS class to add to the .modal-message (optional)
  // @param autoCloseTimeout: time in millis to wait before automatically closing the modal
  // (optional)
  // @param clickBackdropToClose: click the backdrop to close the modal (optional)
  // @param clickAnywhereToClose: click anywhere in the modal to close it (optional)
  // @param onBackdropClick: handler fired whenever the backdrop is clicked (optional)
  display = (
    contentHtmlOrReactComponent,
    classToAdd,
    autoCloseTimeout,
    clickBackdropToClose,
    clickAnywhereToClose,
    onBackdropClick,
  ) => {
    document.body.classList.add('modal-open');

    // 2 renders required to get animations working smoothly:
    // First render:
    // • Add display: block
    // • Add custom class
    this.setState({
      displayBlock: true,
      classToAdd,
      clickBackdropToClose,
      clickAnywhereToClose,
      onBackdropClick,
    });
    // Second render:
    // • Add "open" classes
    // • Set inner content
    window.setTimeout(() => {
      this.setState({
        open: true,
        contentHtmlOrReactComponent,
      });
    });

    if (autoCloseTimeout) {
      window.setTimeout(this.hide, autoCloseTimeout);
    }
  };

  containerClicked = () => {
    const { open, clickAnywhereToClose } = this.state;
    if (open && clickAnywhereToClose) {
      this.hide();
    }
  };

  backdropClicked = () => {
    const { open, clickBackdropToClose, onBackdropClick } = this.state;
    if (open && clickBackdropToClose) {
      this.hide();
    }
    if (typeof onBackdropClick === 'function') onBackdropClick();
  };

  render() {
    const {
      contentHtmlOrReactComponent, open, classToAdd, displayBlock,
    } = this.state;
    const isHtmlString = typeof contentHtmlOrReactComponent === 'string';
    const content = isHtmlString
      ? <div dangerouslySetInnerHTML={{ __html: `<div>${contentHtmlOrReactComponent}</div>` }} />
      : contentHtmlOrReactComponent;

    return (
      <div className="modal-container" onClick={this.containerClicked}>
        <div
          className={`modal-backdrop ${open ? ' open' : ''}`}
          onClick={this.backdropClicked}
        />
        <div
          className={
            `modal-message ${open ? ' open' : ''}  ${classToAdd}`
          }
          style={{ display: displayBlock ? 'block' : 'none' }}
        >
          {content}
        </div>
      </div>
    );
  }
}

export default Modal;
