import validateScreenConfig from '../libs/validateScreenConfig';
import Console from '../libs/Console';

// Convenience wrapper around ScreenConfig from the API
// The Router provides an instance as a prop to each screen
// NOTE: Should only be imported by Root.js!  Do not import in lower level components!
class ScreenConfig {
  constructor(configFromApi, spec) {
    // Convert to object!
    this.resources = configFromApi.resources.reduce((acc, r) => {
      acc[r.key] = r.value;
      return acc;
    }, {});
    this.spec = spec;
    this.errors = validateScreenConfig(configFromApi, spec);
    this.errors.forEach((error) => {
      Console.error(error);
    });
  }
}

export default ScreenConfig;
