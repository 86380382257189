import ProductBasicsScreenConfigSpec from '../../components/screens/ProductBasics/screen-config-spec.json';
import LinkTilesScreenConfigSpec from '../../components/screens/LinkTiles/screen-config-spec.json';
import PdfTilesScreenConfigSpec from '../../components/screens/PdfTiles/screen-config-spec.json';
import EventsScreenConfigSpec from '../../components/screens/Events/screen-config-spec.json';
import GetStartedScreenConfigSpec from '../../components/screens/GetStarted/screen-config-spec.json';
import VideosScreenConfigSpec from '../../components/screens/Videos/screen-config-spec.json';

export default {
  ProductBasicsScreenConfigSpec,
  LinkTilesScreenConfigSpec,
  PdfTilesScreenConfigSpec,
  EventsScreenConfigSpec,
  GetStartedScreenConfigSpec,
  VideosScreenConfigSpec,
};
