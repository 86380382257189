import React from 'react';
import PropTypes from 'prop-types';
import UploadIcon from '../../../../../assets/images/icons/upload-1.svg';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  numberOfEventsToShare: PropTypes.number.isRequired,
};

const EventShareMenu = ({ handleClick, handleCancel, numberOfEventsToShare }) => {
  const className = `bottom-actions ${numberOfEventsToShare ? ' active' : ''}`;
  return (
    <div className={className}>
      <div className="bottom-actions-buttons">
        <button
          type="button"
          className="btn btn-primary btn-blue"
          onClick={handleClick}
        >
          <UploadIcon />
          {`Share ${numberOfEventsToShare} Event${numberOfEventsToShare > 1 ? 's' : ''}`}
        </button>
        <button
          type="button"
          className="btn connect-events-share-cancel-button"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

EventShareMenu.propTypes = propTypes;

export default EventShareMenu;
