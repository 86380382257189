const JUMP_START_STATES = {
  EMPTY: {
    name: '--',
    value: '',
  },

  three_day_combined: {
    name: '3 Day Combined',
    value: '3_day_combined',
  },

  two_day_macos: {
    name: '2 Day macOS',
    value: '2_day_macos',
  },

  half_day_remote_2x: {
    name: 'Half Day Remote x 2',
    value: 'half_day_remote_2x',
  },

  half_day_remote: {
    name: 'Half Day Remote',
    value: 'half_day_remote',
  },
};

export default JUMP_START_STATES;
