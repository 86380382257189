import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  attemptFailed: PropTypes.bool.isRequired,
  attemptFailedMessage: PropTypes.string.isRequired,
  unlockMessage: PropTypes.string.isRequired,
};

// Check for password in search parameters (expecting '?j=secret')
// Solicit from user if not present
const EventsLock = ({
  handleChange, attemptFailed, attemptFailedMessage, unlockMessage,
}) => {
  const codeMessage = attemptFailed
    ? <p>{attemptFailedMessage}</p>
    : <p>{unlockMessage}</p>;
  return (
    <div className="connect-login">
      {codeMessage}
      <input
        type="tel"
        placeholder=""
        maxLength="4"
        onChange={handleChange}
      />
    </div>
  );
};

EventsLock.propTypes = propTypes;

export default EventsLock;
