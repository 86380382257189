export const getComponentSlug = (component, maxLength = 20) => {
  // Video content gets special handling
  if (component.component_type === 'video'){
    return `video-${component.youtube_id}`;
  }

  // Step 1: Convert to lowercase
  let slug = component.search_string?.toLowerCase() ?? 'not_found';

  // Step 2: Remove special characters and punctuation
  slug = slug.replace(/[^\w\s-]/g, '');

  // Step 3: Replace spaces and non-alphanumeric characters with hyphens
  slug = slug.trim().replace(/[\s_-]+/g, '-');

  // Step 4: Remove any leading numerals
  slug = slug.replace(/^\d+/, '');

  // Step 5: Truncate the slug
  slug = slug.substring(0, maxLength);

  return slug;
};

