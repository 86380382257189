import mailerApi from '../../../api/mailerApi';
import EditionConfig from '../../../config/EditionConfig';

// Requested by Josh to also receive all PDF shares
// eslint-disable-next-line max-len
const emailToBcc = 'emailtosalesforce@5-2073yy6b9zvqgyxzs0n0vmemfoziv3r898ni413c9otgi9lppu.8-cow4eae.na8.le.salesforce.com';

function sendSharedResourceEmail(email, customMessage, pdfs) {
  const resources = pdfs.map((pdf) => {
    const {
      href, title, description, image,
    } = pdf;

    return {
      url: href, title, description, image,
    };
  });
  const data = {
    personalizations: [{
      to: [{ email }],
      bcc: [{ email: emailToBcc }],
      dynamic_template_data: {
        resources,
        customMessage,
        editionPathname: EditionConfig.get().pathname,
      },
    }],
    from: { email: EditionConfig.get().from_email_address },
    template_id: 'd-a193cdea106d4163a61989a4ef3aea13',
  };
  return mailerApi.sendEmail(data);
}

export default sendSharedResourceEmail;
