// Service to allow console messages to be conditionally exposed in the dev console or in the UI
// Eg:
// > import Console from '../'
// > Console.error('Uh oh, something is not configured right')
//
// See DebugConsole for the UI

/* eslint no-console: 0 */
import Env from '../config/Env';

const errorsForDebugConsole = [];

const shouldLogToConsole = () => {
  return Env.isDev || Env.isDebugMode;
};

const log = (args) => {
  if (shouldLogToConsole()) {
    console.log(args);
  }
  if (Env.isDebugMode) {
    errorsForDebugConsole.push({ msg: args, type: 'log' });
  }
};
const error = (args) => {
  if (shouldLogToConsole()) {
    console.error(args);
  }
  if (Env.isDebugMode) {
    errorsForDebugConsole.push({ msg: args, type: 'error' });
  }
};
const info = (args) => {
  if (shouldLogToConsole()) {
    console.info(args);
  }
  if (Env.isDebugMode) {
    errorsForDebugConsole.push({ msg: args, type: 'info' });
  }
};

const Console = {
  log,
  error,
  info,
  errorsForDebugConsole,
};

export default Console;
