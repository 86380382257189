import EditionConfig from '../config/EditionConfig';

const areCookiesAllowed = () => {
  // If GDPR compliance is not required, we can use cookies freely
  const config = EditionConfig.get();
  if (config && !config.gdpr_compliance_enabled) return true;

  // The jamf-cookie-consent library sets the "cookieconsent_status" cookie with either:
  //  - "dismiss": The user consents and we can use cookies
  //  - "decline": The user declines and we should not use cookies
  return document.cookie.indexOf('cookieconsent_status=dismiss') !== -1;
};

export default areCookiesAllowed;
