import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  numColumns: PropTypes.number.isRequired,
};

const EventTableRowLoadMore = (({ handleClick, numColumns }) => {
  return (
    <tr>
      <td className="load-more" colSpan={numColumns}>
        <button type="button" className="btn btn-block" onClick={handleClick}>
          Load more events
        </button>
      </td>
    </tr>
  );
});

EventTableRowLoadMore.propTypes = propTypes;

export default EventTableRowLoadMore;
