import React from 'react';
import flatten from 'flat';
import { editionApi } from './api';
import EditionConfig from './config/EditionConfig';
import Root from './config/Root';
import Analytics from './libs/Analytics';
import Console from './libs/Console';
import userLocale from './libs/userLocale';

userLocale.checkUrlForLocaleOverride(window.location);

const { languageCode, countryCode } = userLocale.get();

class FrontendApp extends React.Component {
  constructor() {
    super();

    this.state = { loading: true };
  }

  componentDidMount() {
    editionApi.get(languageCode, countryCode)
      .then(({ data }) => {
        EditionConfig.set(data.edition);

        // Analytics
        Analytics.track('Edition Loaded', flatten(data.edition));
        const savedResellerInfo = JSON.parse(localStorage.getItem('JP_ResellerInformation'));
        if (savedResellerInfo) {
          Analytics.identify(flatten(savedResellerInfo));
        }

        this.setState({ loading: false });
      })
      .catch((error) => {
        Console.error('Uh oh', error);
      });
  }

  render() {
    const { loading } = this.state;

    return loading ? <div className="loader" /> : <Root />;
  }
}

export default FrontendApp;
