import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import EditionConfig from '../../../config/EditionConfig';
import ScreenConfigPropType from './ScreenConfigPropType';
import ModalService from '../../../libs/ModalService';
import GetStartedProductChooser from './GetStartedProductChooser';
import GetStartedFormContainer from './GetStartedFormContainer';
import Analytics from '../../../libs/Analytics';

const propTypes = {
  screenConfig: ScreenConfigPropType.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

class GetStarted extends React.Component {
  constructor(props) {
    super(props);

    const { screenConfig } = props;

    // null | 'Jamf Now' | 'Jamf Pro'
    let selectedProduct;
    switch (screenConfig.resources.product_focus_string) {
    case 'jamf_now':
      selectedProduct = 'Jamf Now';
      break;
    case 'jamf_pro':
      selectedProduct = 'Jamf Pro';
      break;
    default:
      selectedProduct = null;
    }

    this.state = {
      selectedProduct,
      passedInFormData: null,
    };
    this.modalDisplayed = false;
    this.industries = [
      {
        value: 'Advertising & Marketing',
        label: screenConfig.resources.industry_advertising_and_marketing,
      },
      { value: 'Banking', label: screenConfig.resources.industry_banking },
      { value: 'Communications', label: screenConfig.resources.industry_communications },
      { value: 'Construction', label: screenConfig.resources.industry_construction },
      { value: 'Field Services', label: screenConfig.resources.industry_field_services },
      { value: 'Finance', label: screenConfig.resources.industry_finance },
      { value: 'Government', label: screenConfig.resources.industry_government },
      { value: 'Healthcare', label: screenConfig.resources.industry_healthcare },
      { value: 'Hospitality', label: screenConfig.resources.industry_hospitality },
      { value: 'Manufacturing', label: screenConfig.resources.industry_manufacturing },
      { value: 'Other', label: screenConfig.resources.industry_other },
      { value: 'Real Estate', label: screenConfig.resources.industry_real_estate },
      {
        value: 'Restaurant & Food Services',
        label: screenConfig.resources.industry_restaurant_and_food_services,
      },
      { value: 'Retail', label: screenConfig.resources.industry_retail },
      { value: 'Sales', label: screenConfig.resources.industry_sales },
      { value: 'Technology', label: screenConfig.resources.industry_technology },
      { value: 'Transportation', label: screenConfig.resources.industry_transportation },
    ];
  }

  componentDidMount() {
    this.initChoiceFlow();
  }

  // Choice is required (only) if screen is configured for both products
  choiceRequired = () => this.props.screenConfig.resources.product_focus_string === 'both';

  // Displays the chooser modal if relevant
  initChoiceFlow = () => {
    const { selectedProduct } = this.state;

    if (this.choiceRequired() && !selectedProduct) {
      this.displayModal();
    }
  };

  // When the backdrop is clicked, close the modal and return to the previous screen
  onBackdropClick = () => {
    const { history } = this.props;
    ModalService.hide();
    this.modalDisplayed = false;
    history.goBack();

    Analytics.track('Product Chooser Aborted');
  };

  productSelected = (productName, passedInFormData) => {
    this.setState({
      selectedProduct: productName,
      passedInFormData,
    });
    this.hideModal();
  };

  startOver = () => {
    this.setState({ selectedProduct: null }, this.initChoiceFlow);

    Analytics.track('Get Started Choose Again Clicked');
  };

  hideModal = () => {
    // Wait a tick to let current render go through
    window.setTimeout(() => {
      if (this.modalDisplayed) {
        ModalService.hide();
        this.modalDisplayed = false;
      }
    });
  };

  displayModal() {
    // Wait a tick to let current render go through
    window.setTimeout(() => {
      if (!this.modalDisplayed) {
        const content = (
          <GetStartedProductChooser
            industryOptions={[...this.industries]}
            productSelected={this.productSelected}
          />
        );
        ModalService.display(content, 'modal-chooser', null, false, false, this.onBackdropClick);
        this.modalDisplayed = true;
      }
    });
  }

  render = () => {
    const { selectedProduct, passedInFormData } = this.state;
    const { screenConfig } = this.props;
    const { resources } = screenConfig;
    const editionConfig = EditionConfig.get();

    // Render the GetStartedForm
    return (
      <div>
        <GetStartedFormContainer
          alwaysShowResellerEmailField={resources.always_show_reseller_email_field}
          showResellerSalesDepartment={resources.show_reseller_sales_department}
          showResellerStores={resources.show_reseller_stores}
          jamfNowCustomerFollowupEmailEnabled={resources.jamf_now_customer_followup_email_enabled}
          jamfNowCustomerFollowupEmailTemplateId={
            resources.jamf_now_customer_followup_email_template_id_string
          }
          jamfNowRepFollowupEmailEnabled={resources.jamf_now_rep_followup_email_enabled}
          jamfNowRepFollowupEmailTemplateId={
            resources.jamf_now_rep_followup_email_template_id_string
          }
          leadSourceDetail={resources.lead_source_detail_string}
          product={selectedProduct}
          startOver={this.choiceRequired() ? this.startOver : null}
          passedInFormData={passedInFormData}
          industries={this.industries}
          marketoFormId={
            selectedProduct === 'Jamf Pro' ? resources.jamf_pro_marketo_form_id : resources.jamf_now_marketo_form_id
          }
          countryLabel={resources.country_label}
          selectPrompt={resources.select_prompt}
          stateLabel={resources.state_label}
          appleStoreLabel={resources.apple_store_label}
          emailLabel={resources.email_label}
          resellerFormLabel={resources.reseller_form_label}
          firstNameLabel={resources.first_name_label}
          lastNameLabel={resources.last_name_label}
          commentsAndQuestionsLabel={resources.comments_and_questions_label}
          customerFormLabel={resources.customer_form_label}
          companyLabel={resources.company_label}
          numberOfAppleDevicesLabel={resources.number_of_apple_devices_label}
          industryLabel={resources.industry_label}
          privacyMessage={resources.privacy_message}
          successMessage={resources.success_message}
          contactUsButtonLabel={resources.contact_us_button_label}
          contactUsButtonSubmittingLabel={resources.contact_us_button_submitting_label}
          editionSalesforceId={editionConfig.salesforce_id}
        />
      </div>
    );
  };
}

GetStarted.propTypes = propTypes;

export default GetStarted;
