// TODO: replace with server side data!
const MAIN_PRODUCTS = {

  MACOS: {
    id: 'macos',
    displayName: 'macOS',
  },

  IOS: {
    id: 'ios',
    displayName: 'iOS / tvOS',
  },

  SCCM_PLUGIN: {
    id: 'sccm_plugin',
    displayName: 'SCCM Plugin',
  },

  JAMF_NOW: {
    id: 'jamf_now',
    displayName: 'Jamf Now',
  },

  JAMF_NOW_PLUS: {
    id: 'jamf_now_plus',
    displayName: 'Jamf Now Plus',
  },
};

export default MAIN_PRODUCTS;
