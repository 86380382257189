const validateScreenConfig = (configFromApi, configSpec) => {
  const { resources: resourcesFromApi } = configFromApi;
  // Copy the spec so when we delete keys we don't tamper with the original
  const resourcesSpec = { ...configSpec.resources };
  const errors = [];

  // Validate resources
  resourcesFromApi.forEach((resourceFromApi) => {
    if (!Object.prototype.hasOwnProperty.call(resourcesSpec, resourceFromApi.key)) {
      errors.push(`Extraneous resource: ${resourceFromApi.key}.  
The backend supplied this key, but it is not present in the screen config spec.`);
      return;
    }

    const typeFromSpec = resourcesSpec[resourceFromApi.key];
    if (typeFromSpec !== resourceFromApi.type) {
      errors.push(`Type mismatch for resource: ${resourceFromApi.key}.  
Expected ${typeFromSpec} but found ${resourceFromApi.type}`);
      return;
    }

    // Remove the key if it's been found, we'll tally these up later to ID missing properties
    delete resourcesSpec[resourceFromApi.key];
  });

  // If the key is still present, the resource was missing
  Object.keys(resourcesSpec).forEach((key) => {
    errors.push(`Required resource missing: ${key} of type: ${resourcesSpec[key]}`);
  });

  return errors;
};

export default validateScreenConfig;
