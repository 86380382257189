import LinkTiles from './LinkTiles';
import PdfTiles from './PdfTiles';
import ProductBasics from './ProductBasics';
import Events from './Events';
import GetStarted from './GetStarted';
import Videos from './Videos';
import CustomScreen from './CustomScreen';

// Directory of all screens
// Allows blanket import so screens can be dynamically selected
// --> Don't forget to add the screen-config-spec to /config/screen-config-specs/index.json
export default {
  LinkTiles,
  PdfTiles,
  ProductBasics,
  Events,
  GetStarted,
  Videos,
  CustomScreen,
};
