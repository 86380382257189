import React from 'react';
import PropTypes from 'prop-types';

const propTypes = { countryCode: PropTypes.string.isRequired };

class IconFlag extends React.Component {
  render() {
    const { countryCode } = this.props;
    const upperCase = countryCode.toUpperCase();
    const src = `/flags/${upperCase}.png`;
    return (
      <img alt={`Flag for ${upperCase} locale`} className="icon-flag" src={src} />
    );
  }
}

IconFlag.propTypes = propTypes;

export default IconFlag;
