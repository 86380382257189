import * as moment from 'moment';
import EditionConfig from '../../../config/EditionConfig';

const getFormattedEvents = (events, screenConfig) => {
  let filtered = events;

  // Apply topic filter if enabled
  if (screenConfig.resources.topic_filter_string) {
    filtered = filtered.filter((e) => {
      return e.topic === screenConfig.topic_filter_string;
    });
  }

  // Apply locale filter if enabled
  if (screenConfig.locale_filter_array) {
    // 1. If event.locales is empty, the event is "global" and should always be displayed
    // 2. They are both arrays, so if they "intersect", we should display them
    filtered = filtered.filter((event) => {
      if (event.locales.length === 0) return true;
      const intersection = screenConfig.locale_filter_array.filter((l) => {
        return event.locales.includes(l);
      });
      return intersection.length > 0;
    });
  }

  if (screenConfig.resources.audience_string === 'All') {
    // 'All' should omit 'Internal' events but show all others
    filtered = filtered.filter(e => e.audience !== 'Internal');
  } else if (screenConfig.resources.audience_string === 'Internal') {
    // 'Internal' should show only 'Internal' events
    filtered = filtered.filter(e => e.audience === screenConfig.resources.audience_string);
  } else {
    // 'Retail' or 'Enterprise' show their own audiences + 'All'
    filtered = filtered.filter((e) => {
      return e.audience === 'All' || e.audience === screenConfig.resources.audience_string;
    });
  }

  return filtered.map((event) => {
    // Strip off the trailing ' Event'
    const type = event.type.replace(' Event', '');
    // Unescape double newlines
    const message = event.message ? event.message.replace('  \\n\\n  ', '\n\n') : '';
    const localTimeStrippedOfTimeZoneOffset = event.local_date ? event.local_date.slice(0,
      event.local_date.length - 6) : null;
    const config = EditionConfig.get();
    const timeFormat = config.current_country.time_format === '24_hour' ? 'H:mm' : 'h:mm A';

    return {
      id: event.id,
      audience: event.audience,
      product: event.product,
      topic: event.topic,
      type,
      // Combined for use in alphabetical column sorting
      topicAndType: `${event.topic} ${type}`,
      message,
      details: event.details,
      url: event.url,
      ical_url: event.ical_url,
      locales: event.locales,

      // Split composite dateTime into separate, formatted date and time fields
      dateTime: event.date,
      date: event.date ? moment(event.date).format('ll') : null,
      time: event.date ? moment(event.date).format(timeFormat) : null,
      localTime: localTimeStrippedOfTimeZoneOffset
        ? moment(localTimeStrippedOfTimeZoneOffset).format(timeFormat)
        : '',
      timezone: event.timezone,
    };
  });
};

export default getFormattedEvents;
