import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    msg: PropTypes.string,
    type: PropTypes.string,
  })),
};

const defaultProps = {
  errors: [],
};

class DebugConsole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimized: true,
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  toggle = () => {
    const { minimized } = this.state;
    this.setState({ minimized: !minimized });
  };

  scrollToBottom() {
    this.bottomElem.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { errors } = this.props;
    const { minimized } = this.state;
    const errorItems = errors.map((e) => {
      return <li className={e.type} key={e.msg}>{e.msg}</li>;
    });

    return (
      <div className={`debug-console ${minimized ? 'minimized' : ''}`}>
        <p>
          <strong>{errors.length} Errors</strong>
          <small>
            {minimized ? '' : ' To remove this debug console, reload the page without "#debug" in the URL.'}
          </small>
          <button type="button" onClick={this.toggle}>
            {minimized ? 'show' : 'hide'}
          </button>
        </p>
        <div className="debug-console-body">
          <ul>{errorItems}</ul>
          <div
            ref={(elem) => {
              this.bottomElem = elem;
            }}
          />
        </div>
      </div>
    );
  }
}

DebugConsole.propTypes = propTypes;
DebugConsole.defaultProps = defaultProps;

export default DebugConsole;
