const lsKey = (isJamfNow) => {
  return isJamfNow ? 'JP_Saved_Jamf_Now_Product_Selections' : 'JP_Saved_Jamf_Pro_Product_Selections';
};

const SavedProductSelections = {
  get: (isJamfNow) => {
    return JSON.parse(localStorage.getItem(lsKey(isJamfNow)));
  },
  set: (productSelections, isJamfNow) => {
    localStorage.setItem(lsKey(isJamfNow), JSON.stringify(productSelections));
  },
  clear: (isJamfNow) => {
    localStorage.removeItem(lsKey(isJamfNow));
  },
};

export default SavedProductSelections;
