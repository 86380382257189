import axios from 'axios';
import baseApiUrl from './baseApiUrl';

// The rails backend adds the edition ID to the html tag
const editionId = document.querySelector('html').getAttribute('data-edition-id');

function get(languageCode, countryCode) {
  return axios.get(
    `${baseApiUrl}/editions/${editionId}`,
    { params: { country_code: countryCode, language_code: languageCode } },
  );
}

export default { get };
