import React from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import flatten from 'flat';
import Console from '../../../libs/Console';
import JamfIcon from '../../../../../assets/images/icons/jamf.svg';
import { JAMF_NOW_THRESHOLD, JAMF_PRO_THRESHOLD } from './PRODUCT_CHOOSER_WEIGHTS';
import getProductChooserFields from './getProductChooserFields';
import Analytics from '../../../libs/Analytics';

const propTypes = {
  productSelected: PropTypes.func.isRequired,
  industryOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

class GetStartedProductChooser extends React.Component {
  constructor(props) {
    super(props);
    const { industryOptions } = this.props;

    this.state = {
      percentComplete: 0,
      totalWeight: 0,
      recommendation: null,
      fields: getProductChooserFields(industryOptions),
    };
  }

  // iOS scroll bug prevention
  // When reaching the top or bottom of the inner scroll element,
  // iOS will attempt to scroll the Window element
  componentDidMount() {
    disableBodyScroll(this.scrollContainer);

    Analytics.track('Product Chooser Displayed');
  }

  componentWillUnmount() {
    enableBodyScroll(this.scrollContainer);
  }

  getTotalWeight = (fields, state) => {
    let weight = 0;
    fields.forEach((field) => {
      const w = field.getWeight(field.value, state);
      weight += w;
    });
    return weight;
  };

  getPercentComplete(fields) {
    let totalVisibleFields = 0;
    let totalAnsweredVisibleFields = 0;
    fields.forEach((field) => {
      // Don't count any of the conditional fields for completeness purposes
      // (regardless of visibility)
      if (field.secondary) {
        return null;
      }
      totalVisibleFields += 1;

      if (this.fieldHasValue(field)) totalAnsweredVisibleFields += 1;
      return null;
    });
    return Math.round(totalAnsweredVisibleFields / totalVisibleFields * 100);
  }

  getRecommendation = (totalWeight, percentComplete) => {
    const complete = percentComplete >= 100;

    if (complete) {
      return totalWeight <= 0 ? 'Jamf Now' : 'Jamf Pro';
    }

    // If incomplete, only display a recommendation if thresholds are met
    if (!complete) {
      if (totalWeight > JAMF_PRO_THRESHOLD) {
        return 'Jamf Pro';
      }
      if (totalWeight < JAMF_NOW_THRESHOLD) {
        return 'Jamf Now';
      }
    }

    return null;
  };

  // Flatten fields and values to s simple map of { field: value }
  getSimpleFieldValueMap = (fields) => {
    return flatten(fields.reduce((acc, field) => {
      acc[field.id] = field.value;
      return acc;
    }, {}));
  };

  getFieldMarkup = (field) => {
    const isHidden = typeof field.hideWhen === 'function' && field.hideWhen(this.state);
    if (isHidden) {
      return null;
    }

    let input;
    switch (field.type) {
    case 'text':
      input = this.getTextInput(field);
      break;
    case 'number':
      input = this.getNumberInput(field);
      break;
    case 'dropdown':
      input = this.getDropdownInput(field);
      break;
    case 'yes-no':
      input = this.getYesNoInput(field);
      break;
    case 'checkboxes':
      input = this.getCheckboxesInput(field);
      break;
    default:
      input = null;
    }

    return (
      <div className={`field ${field.secondary ? ' secondary' : ''}`} key={field.id}>
        {field.secondary ? null : <label htmlFor={field.id}>{field.label}</label>} {input}
      </div>
    );
  };

  getDropdownInput(field) {
    const onChange = (e) => {
      const { value } = e.target;
      this.handleFieldChange(field.id, value);
    };
    return (
      <select onChange={onChange} value={field.value} key={field.id} id={field.id}>
        <option value="">Select one...</option>
        {field.options.map((option) => {
          return <option value={option.value} key={option.value}>{option.label}</option>;
        })}
      </select>
    );
  }

  getTextInput(field) {
    const onChange = (e) => {
      const { value } = e.target;
      this.handleFieldChange(field.id, value);
    };
    return (
      <div key={field.id}>
        <input type="text" value={field.value} id={field.id} onChange={onChange} />
      </div>
    );
  }

  getNumberInput(field) {
    const onChange = (e) => {
      const { value } = e.target;
      this.handleFieldChange(field.id, value);
    };
    return (
      <div key={field.id}>
        <input type="number" step="1" value={field.value} min="0" id={field.id} onChange={onChange} />
      </div>
    );
  }

  getYesNoInput(field) {
    const yesClicked = () => {
      this.handleFieldChange(field.id, true);
    };

    const noClicked = () => {
      this.handleFieldChange(field.id, false);
    };

    return (
      <div key={field.id}>
        <div className="btn-group" role="group" id={field.id}>
          <a
            className={`btn btn-small btn-yes-no ${this.activeClassWhenTrue(field.value === true)}`}
            onClick={yesClicked}
          >
            Yes
          </a>
          <a
            className={`btn btn-small btn-yes-no ${this.activeClassWhenTrue(field.value === false)}`}
            onClick={noClicked}
          >
            No
          </a>
        </div>
      </div>
    );
  }

  getCheckboxesInput(field) {
    const onChange = (e) => {
      const { value } = e.target;

      // Add or remove the changed item from the array
      const arr = field.value.slice();
      const index = field.value.indexOf(value);
      if (index === -1) {
        arr.push(value);
      } else {
        arr.splice(index, 1);
      }

      this.handleFieldChange(field.id, arr);
    };

    return (
      <div key={field.id}>
        {field.options.map((option) => {
          return (
            <div className="checkbox" key={option.value}>
              <label>
                <input
                  type="checkbox"
                  value={option.value}
                  id={option.value.replace(' ', '')}
                  checked={field.value.indexOf(option.value) !== -1}
                  onChange={onChange}
                />
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    );
  }

  // Entered values can be true, false, 'string' or ['foo']
  fieldHasValue = (field) => {
    return (
      field.value === true
      || field.value === false
      || (field.value !== null && field.value.length)
    );
  };

  activeClassWhenTrue = (bool) => {
    return bool ? ' active' : '';
  };

  handleFormSubmit = () => { /*noop*/ };

  handleInvalidForm = () => { /*noop*/ };

  handleFieldChange = (fieldId, fieldValue) => {
    const { state } = this;
    const { fields, recommendation } = state;
    const field = fields.find(f => f.id === fieldId);
    if (!field) {
      Console.error('Field not found:', fieldId);
      return;
    }

    field.value = fieldValue;
    const totalWeight = this.getTotalWeight(fields, state);
    const percentComplete = this.getPercentComplete(fields, state);
    const newRecommendation = this.getRecommendation(totalWeight, percentComplete);

    this.setState({
      fields,
      totalWeight,
      percentComplete,
      recommendation: newRecommendation,
    });

    if (newRecommendation && newRecommendation !== recommendation) {
      Analytics.track('Product Chooser Recommendation Displayed', {
        productName: newRecommendation,
        ...this.getSimpleFieldValueMap(fields),
        totalWeight,
        percentComplete,
      });
    }
  };

  handleRecommendationSelected = (productName) => {
    const { productSelected } = this.props;
    const { fields, totalWeight, percentComplete } = this.state;
    productSelected(productName, [...fields]);

    Analytics.track('Product Chooser Recommendation Selected', {
      productName,
      ...this.getSimpleFieldValueMap(fields),
      totalWeight,
      percentComplete,
    });
  };

  handleSkipToProduct = (productName) => {
    const { productSelected } = this.props;
    const { fields, totalWeight, percentComplete } = this.state;
    productSelected(productName, [...fields]);

    Analytics.track('Product Chooser Skip To Product Clicked', {
      productName, totalWeight, percentComplete,
    });
  };

  startOver = () => {
    const { industryOptions } = this.props;
    // Feels more natural with a slight pause!
    setTimeout(() => {
      this.setState({
        percentComplete: 10,
        totalWeight: 0,
        recommendation: null,
        fields: getProductChooserFields(industryOptions),
      });
    }, 100);

    Analytics.track('Product Chooser Started Over');
  };

  render = () => {
    const { recommendation, fields } = this.state;
    const verdictContent = (
      <div>
        <div className={recommendation === 'Jamf Now' ? 'recommendation-jamf-now' : 'recommendation-jamf-pro'}>
          We Recommend
        </div>
        <a
          className={`btn ${recommendation === 'Jamf Now' ? ' btn-jamf-now' : ' btn-jamf-pro'}`}
          onClick={() => {
            this.handleRecommendationSelected(recommendation);
          }}
        >
          <JamfIcon /> Get Started
          with {recommendation === 'Jamf Now' ? 'Jamf Now' : 'Jamf Pro'}
        </a>
        <a
          className="btn btn-link"
          onClick={() => {
            this.startOver();
          }}
        >
          Start over
        </a>
      </div>
    );
    const verdictBar = (
      <div className={`verdict-bar ${recommendation ? ' active' : ''}`}>{verdictContent}</div>
    );

    const skip = (
      <div className={`verdict-bar ${!recommendation ? ' active' : ''}`}>
        <p>
          Skip to {' '}
          <a
            onClick={() => {
              this.handleSkipToProduct('Jamf Now');
            }}
          >
            Jamf Now
          </a>
          {' '} or{' '}
          <a
            onClick={() => {
              this.handleSkipToProduct('Jamf Pro');
            }}
          >
            Jamf Pro
          </a>
          {' '}
        </p>
      </div>
    );

    return (
      <div className="get-started-product-chooser">
        <form
          onSubmit={this.handleFormSubmit}
          onInvalid={this.handleInvalidForm}
        >
          <div
            className="scroll"
            ref={(scrollContainer) => {
              this.scrollContainer = scrollContainer;
            }}
          >
            <div className="get-started-product-chooser-header">
              <h1>Which product is right for my customer?</h1>
              <p>Answer any questions you know to receive our product recommendation</p>
            </div>
            <div className="fieldset">
              {fields.filter(f => f.location === 'customer1').map(this.getFieldMarkup)}
              {fields.filter(f => f.location === 'customer2').map(this.getFieldMarkup)}
              {fields.filter(f => f.location === 'customer3').map(this.getFieldMarkup)}
              {fields.filter(f => f.location === 'requirements1').map(this.getFieldMarkup)}
              {fields.filter(f => f.location === 'requirements2').map(this.getFieldMarkup)}
              {fields.filter(f => f.location === 'requirements3').map(this.getFieldMarkup)}
            </div>
          </div>
          <div className="fade-to-white" />
          {verdictBar}
          {skip}
        </form>
      </div>
    );
  };
}

GetStartedProductChooser.propTypes = propTypes;

export default GetStartedProductChooser;
