import moment from 'moment';
import { TIMING_FILTER_LIVE } from './eventTimingOptions';

const getUnixTime = (event) => {
  if (!event.dateTime) return moment().unix();
  return moment(event.dateTime).unix();
};

const getHours = (event) => {
  // eg 12.52
  return moment(event.dateTime).hours() + (moment(event.dateTime).minutes() / 60);
};

// Only return dates from today or future days (ignores time)
// (doing this late in the sort process so that it's easy to accommodate a possible
// "Show Past Events" toggle someday)
const removePastEvents = (events) => {
  const now = moment();
  return events.filter((event) => {
    // If no date present, keep it
    if (!event.dateTime) return true;

    const eventDate = moment(event.dateTime);
    return eventDate.isAfter(now, 'day') || eventDate.isSame(now, 'date');
  });
};

const filterByLocale = (events, localeFilters) => {
  if (localeFilters.length) {
    return events.filter((event) => {
      // They are both arrays, so if they "intersect", we should display them
      return localeFilters.filter((l) => {
        return event.locales.includes(l);
      }).length;
    });
  }
  return events;
};

const filterByTiming = (events, timingFilters) => {
  // Show all when no filters or all filters are selected
  const showAll = timingFilters.length === 0;
  const showLive = timingFilters.indexOf(TIMING_FILTER_LIVE) !== -1;

  if (showAll) return events;

  // Only filter options are Live and On-Demand
  // Live events have a date; On-Demand do not
  if (showLive) {
    return events.filter((event) => !!event.dateTime);
  }
  return events.filter((event) => !event.dateTime);
};

const filterBySearchTerm = (events, searchTerm) => {
  if (searchTerm) {
    // Simple "exact match anywhere" search
    return events.filter((event) => event.search.includes(searchTerm.toLowerCase()));
  }
  return events;
};

const sort = (events, sortColumnId, sortDirection) => {
  let searchedAndSorted = [...events];
  if (sortColumnId === 'date') {
    // Date uses complete dateTime for sorting
    searchedAndSorted = searchedAndSorted.sort((eventA, eventB) => {
      return getUnixTime(eventA) - getUnixTime(eventB);
    });
  } else if (sortColumnId === 'time') {
    // Time uses only the time for sorting
    searchedAndSorted = searchedAndSorted.sort((eventA, eventB) => {
      return getHours(eventA) - getHours(eventB);
    });
  } else {
    // Default sorting method is alphabetical string compare
    searchedAndSorted = searchedAndSorted.sort((eventA, eventB) => {
      return eventA[sortColumnId] - eventB[sortColumnId];
    });
  }

  if (sortDirection === 'desc') {
    searchedAndSorted = searchedAndSorted.reverse();
  }

  return searchedAndSorted;
};

const getSortedSearchedEvents = (
  allEvents, localeFilters, timingFilters, searchTerm, sortColumnId, sortDirection,
) => {
  let searchAndSorted = removePastEvents(allEvents);
  searchAndSorted = filterByLocale(searchAndSorted, localeFilters);
  searchAndSorted = filterByTiming(searchAndSorted, timingFilters);
  searchAndSorted = filterBySearchTerm(searchAndSorted, searchTerm);
  searchAndSorted = sort(searchAndSorted, sortColumnId, sortDirection);
  return searchAndSorted;
};

export default getSortedSearchedEvents;
