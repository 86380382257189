import React from 'react';
// Allows dynamic nav group icon selection from config

// 1. Import all possible nav group icons
import IconBill2 from '../../../assets/images/icons/bill-2.svg';
import IconUser from '../../../assets/images/icons/user.svg';
import IconVideoCamera from '../../../assets/images/icons/video-camera.svg';
import IconShare1 from '../../../assets/images/icons/share-1.svg';
import IconJamf from '../../../assets/images/icons/jamf.svg';

// 2. Provide a map to the components where the keys are the fileName (which is used in the config)
export default {
  'bill-2': <IconBill2 />,
  user: <IconUser />,
  'video-camera': <IconVideoCamera />,
  'share-1': <IconShare1 />,
  jamf: <IconJamf />,
};
