import React from 'react';
import PropTypes from 'prop-types';
import YouTubeEmbed from '../frontend/components/screens/YouTubeEmbed';

const propTypes = {
  youTubeId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const VideoModal = ({ youTubeId, onClose }) => {
  return (
    <>
      <button type="button" className="video-modal-close" onClick={onClose}>
        <span>{'\u00D7'}</span>
      </button>
      <YouTubeEmbed videoId={youTubeId} autoPlay />
    </>
  );
};

VideoModal.propTypes = propTypes;

export default VideoModal;
