import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NoContentWarning from './NoContentWarning';
import getYouTubeThumbUrl from '../admin/libs/getYouTubeThumbUrl';
import VideoModal from './VideoModal';
import NewModal from './NewModal';
import ComponentTarget from "../frontend/components/ComponentTarget";
import {getComponentSlug} from "../frontend/libs/getComponentSlug";

const propTypes = {
  component: PropTypes.shape({
    youtube_id: PropTypes.string,
    fill_background: PropTypes.bool,
  }).isRequired,
  areCookiesAllowed: PropTypes.bool.isRequired,
  devMode: PropTypes.bool,
};

const defaultProps = {
  devMode: false,
};

const CustomScreenComponentVideo = ({ component, devMode, areCookiesAllowed }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { youtube_id: youTubeId, fill_background: fill } = component;
  const hasContent = !!youTubeId;
  const noContentMsg = devMode
    ? <NoContentWarning />
    : null;
  const slug = getComponentSlug(component);

  if (!areCookiesAllowed) {
    return (
      <p>Sorry, the YouTube player uses tracking cookies which you have declined to allow.</p>
    );
  }

  if (!hasContent) return noContentMsg;

  const onClick = () => setModalOpen(true);
  const className = `custom-screen-mixed-component  
    video-component ${!hasContent ? ' incomplete' : ''} ${fill ? 'fill' : ''}`;

  return (
    <>
      <ComponentTarget slug={slug} devMode={devMode} />
      <button type="button" className={className} onClick={onClick}  id={slug}>
        <img alt="" src={getYouTubeThumbUrl(youTubeId)} />
        <span className="glyphicon glyphicon-play" />
      </button>
      <NewModal isOpen={modalOpen} size="lg" className="video-modal-wrapper">
        <VideoModal youTubeId={youTubeId} onClose={() => setModalOpen(false)} />
      </NewModal>
    </>
  );
};

CustomScreenComponentVideo.propTypes = propTypes;
CustomScreenComponentVideo.defaultProps = defaultProps;

export default CustomScreenComponentVideo;
