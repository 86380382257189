import React from 'react';
import * as PropTypes from 'prop-types';
import ScreenConfigPropType from './ScreenConfigPropType';
import VideoPlayer from '../VideoPlayer';

const propTypes = {
  screenConfig: ScreenConfigPropType.isRequired,
  areCookiesAllowed: PropTypes.bool.isRequired,
};

class Videos extends React.Component {
  getEnabledVideos = () => {
    const { screenConfig: { resources } } = this.props;

    return [...Array(6).keys()].reduce((acc, i) => {
      const n = i + 1;

      if (resources[`video_${n}_enabled`]) {
        acc.push({
          youtubeId: resources[`video_${n}_youtube_id`],
          caption: resources[`video_${n}_caption`],
        });
      }

      return acc;
    }, []);
  };

  render = () => {
    const { screenConfig, areCookiesAllowed } = this.props;

    return (
      <section className="videos">
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <VideoPlayer
              videos={this.getEnabledVideos()}
              showClosedCaptions={screenConfig.resources.show_closed_captions || false}
              areCookiesAllowed={areCookiesAllowed}
            />
          </div>
        </div>
      </section>
    );
  };
}

Videos.propTypes = propTypes;

export default Videos;
