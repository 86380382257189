import React from 'react';
import PropTypes from 'prop-types';
import EventPropType from '../../../shared-prop-types/EventPropType';

const propTypes = {
  events: PropTypes.arrayOf(EventPropType).isRequired,
  sendButtonClicked: PropTypes.func.isRequired,
};

class EventShareModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      customMessage: '',
      sendButtonEnabled: false,
    };
  }

  emailInputChanged = (event) => {
    const emailAddress = event.target.value;
    // 99.9% validity test adapted from: https://stackoverflow.com/a/9204568
    const isValid = emailAddress.length && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
    this.setState({
      emailAddress,
      sendButtonEnabled: isValid,
    });
  };

  messageInputChanged = (event) => {
    const customMessage = event.target.value;
    this.setState({ customMessage });
  };

  render = () => {
    const { events, sendButtonClicked } = this.props;
    const { emailAddress, customMessage, sendButtonEnabled } = this.state;
    return (
      <form>
        <h2>Share {events.length} {events.length === 1 ? ' Event' : ' Events'}</h2>
        <div className="form-group">
          <label htmlFor="connectShareEmailInput">Email</label>
          <input
            id="connectShareEmailInput"
            type="email"
            className="form-control"
            placeholder="mail@example.com"
            value={emailAddress}
            onChange={this.emailInputChanged}
          />
        </div>
        <div className="form-group">
          <label htmlFor="connectShareMessage">Message (optional)</label>
          <textarea
            id="connectShareMessage"
            className="form-control"
            maxLength="300"
            onChange={this.messageInputChanged}
            value={customMessage}
          />
        </div>
        <p>
          <button
            type="button"
            className={`btn btn-block ${sendButtonEnabled ? '' : ' disabled'}`}
            onClick={() => sendButtonClicked(emailAddress, customMessage)}
          >
            Share Events
          </button>
        </p>
      </form>
    );
  };
}

EventShareModal.propTypes = propTypes;

export default EventShareModal;
