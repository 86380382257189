import React from 'react';
import PropTypes from 'prop-types';
import EditionConfig from '../../../config/EditionConfig';
import Select from 'react-select';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
};

const EventLocaleFilter = ({ handleChange }) => {

  const options = [
    ...EditionConfig.get().supported_countries,
  ];

  const height = '44px';

  // Adjust heights and spacing to match other event filter controls
  const customStyles = {
    control: (defaults, state) => ({
      ...defaults,
      minHeight: height,
      height: height,
      borderRadius: 0,
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (defaults) => ({
      ...defaults,
      height: height,
      padding: '0 2rem'
    }),

    input: (defaults) => ({
      ...defaults,
      margin: 0,
      padding: 0,
    }),
  };

  return (
    <div className="connect-locale-filter">
      <Select
        isMulti
        isClearable
        isSearchable
        styles={customStyles}
        placeholder="Select country to filter..."
        defaultValue={[]}
        options={options}
        getOptionValue={option => option.code}
        onChange={(selectedCountries) => handleChange(selectedCountries ? selectedCountries.map(c => c.code) : [])}
      >
      </Select>
    </div>
  );
};

EventLocaleFilter.propTypes = propTypes;

export default EventLocaleFilter;
