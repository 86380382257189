import React from 'react';
import PropTypes from 'prop-types';
import { COMPONENT_SIZE_SMALL, COMPONENT_SIZES } from '../admin/components/CustomScreen/componentSizes';

const propTypes = {
  size: PropTypes.oneOf(COMPONENT_SIZES),
  fill: PropTypes.bool,
  editMode: PropTypes.bool,
  additionalProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  fill: false,
  size: COMPONENT_SIZE_SMALL,
  editMode: false,
  additionalProps: {},
};

// Class required to accept ref prop for drag-and-drop
// eslint-disable-next-line react/prefer-stateless-function
class CustomScreenGridLayoutItem extends React.Component {
  render() {
    const {
      size, fill, children, editMode, additionalProps,
    } = this.props;
    const className = `custom-screen-grid-layout-item ${size} ${fill ? 'fill' : ''} ${editMode ? 'edit-mode' : ''}`;

    return <div className={className} {...additionalProps}>{children}</div>;
  }
}

CustomScreenGridLayoutItem.propTypes = propTypes;
CustomScreenGridLayoutItem.defaultProps = defaultProps;

export default CustomScreenGridLayoutItem;
