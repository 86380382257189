import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import EditionConfig from '../../config/EditionConfig';
import Analytics from '../../libs/Analytics';

const propTypes = {
  videoId: PropTypes.string.isRequired,
  showClosedCaptions: PropTypes.bool,
  autoPlay: PropTypes.bool,
};

const defaultProps = {
  showClosedCaptions: false,
  autoPlay: false,
};

const YouTubeEmbed = ({ videoId, showClosedCaptions, autoPlay }) => {
  const opts = {
    height: '720',
    width: '1280',
    playerVars: {
      modestbranding: 1,
      autoplay: autoPlay ? 1 : 0,
      rel: 0,
      wmode: 'opaque',
    },
  };

  const onStateChange = (event) => {
    let eventName;
    /* global YT */
    switch (event.data) {
    case YT.PlayerState.PLAYING:
      eventName = 'Started Playing';
      break;
    case YT.PlayerState.PAUSED:
      eventName = 'Paused';
      break;
    case YT.PlayerState.ENDED:
      eventName = 'Ended';
      break;
    default:
      eventName = null;
    }
    if (eventName) Analytics.track(`Video ${eventName}`, { videoId });
  };

  if (showClosedCaptions) {
    const shortCode = EditionConfig.get().current_language_code.split('-')[0];
    opts.playerVars.hl = shortCode;
    opts.playerVars.cc_lang_pref = shortCode;
    opts.playerVars.cc_load_policy = 1;
  }
  return (
    <YouTube
      videoId={videoId}
      opts={opts}
      onStateChange={onStateChange}
    />
  );
};

YouTubeEmbed.propTypes = propTypes;
YouTubeEmbed.defaultProps = defaultProps;

export default YouTubeEmbed;
