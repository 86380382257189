import React from 'react';
import ModalService from '../../../libs/ModalService';
import ScreenConfigPropType from './ScreenConfigPropType';
import Analytics from '../../../libs/Analytics';

const propTypes = {
  screenConfig: ScreenConfigPropType.isRequired,
};

const openFeatureModal = (modalNumber, resources) => {
  const title = resources[`modal_${modalNumber}_title`];
  const imgSrc = resources[`modal_${modalNumber}_image`];
  const tagline = resources[`modal_${modalNumber}_tagline`];
  const paragraphs = [...Array(3).keys()].map((i) => {
    const num = i + 1;
    const content = resources[`modal_${modalNumber}_paragraph_${num}`];
    return content && content.length ? <p key={content}>{content}</p> : null;
  });
  const content = (
    <div>
      <h2>
        <img alt={title} className="logo" src={resources.product_logo_image} />
        {title}
      </h2>
      <div className="image">
        <img alt={title} src={imgSrc} />
      </div>
      <div className="description">
        {tagline && tagline.length ? <h4>{tagline}</h4> : null}
        {paragraphs}
      </div>
    </div>
  );
  ModalService.display(content, 'modal-feature', null, true);

  Analytics.track('Jamf Pro Basics Feature Modal Displayed', { title, tagline, imgSrc });
};

const openScreenshotModal = (resources) => {
  const content = <img alt={resources.title} src={resources.main_screenshot_image} />;
  ModalService.display(content, ' modal-screenshot', null, true, true);
};

const ProductBasics = ({ screenConfig }) => {
  const { resources } = screenConfig;
  const modalButtons = [...Array(6).keys()].map((i) => {
    const num = i + 1;
    const label = resources[`modal_${num}_title`];
    return (
      <button type="button" key={label} onClick={() => openFeatureModal(num, resources)}>
        {label}
      </button>
    );
  });
  return (
    <section className="jamf-pro-basics">
      <h1>{resources.title}</h1>
      <div className="row row-hero">
        <div className="col-md-6 col-md-offset-1">
          <button
            type="button"
            className="unstyled"
            onClick={() => openScreenshotModal(resources)}
          >
            <img alt={resources.title} src={resources.main_screenshot_image} />
          </button>
        </div>
        <div className="col-md-4">
          <img
            className="logo"
            alt="Product Logo"
            src={resources.product_logo_image}
          />
          <p>{resources.main_product_blurb}</p>
        </div>
      </div>
      <div className="row row-buttons">
        {modalButtons}
      </div>
    </section>
  );
};

ProductBasics.propTypes = propTypes;

export default ProductBasics;
