import React from 'react';
import PropTypes from 'prop-types';
import NoContentWarning from './NoContentWarning';
import SmartLink from './SmartLink';
import ComponentTarget from "../frontend/components/ComponentTarget";
import {getComponentSlug} from "../frontend/libs/getComponentSlug";

const propTypes = {
  devMode: PropTypes.bool,
  component: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    image: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    link: PropTypes.string,
    search_string: PropTypes.string,
  }).isRequired,
};
const defaultProps = {
  devMode: false,
};

const CustomScreenComponentTile = ({ component, devMode }) => {
  const {
    image, title, link, body, id,
  } = component;
  const hasContent = image || title || body;
  if (!hasContent) return devMode ? <NoContentWarning /> : null;

  const slug = getComponentSlug(component);

  const renderUpper = () => {
    const content = (
      <>
        {image && <div className="image"><img alt="" src={image} /></div>}
        <h4>{title}</h4>
      </>
    );

    return link ? <SmartLink href={link} devMode={devMode}>{content}</SmartLink> : content;
  };

  return (
    <div className="custom-screen-mixed-component" key={id} id={slug} tabIndex="0">
      <ComponentTarget slug={slug} devMode={devMode} />
      {renderUpper()}
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  );
};

CustomScreenComponentTile.propTypes = propTypes;
CustomScreenComponentTile.defaultProps = defaultProps;

export default CustomScreenComponentTile;
