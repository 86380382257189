import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
};

const EventShareCheckboxButton = (({ handleClick }) => {
  return (
    <button
      type="button"
      className="connect-button connect-check-mark-button"
      title="Select this event to share"
      onClick={handleClick}
    >
      <span className="connect-check-mark" />
    </button>
  );
});

EventShareCheckboxButton.propTypes = propTypes;

export default EventShareCheckboxButton;
