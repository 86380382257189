/* global olark */
import EditionConfig from '../config/EditionConfig';

let olarkScriptLoaded = false;

export const loadOlark = () => {
  const { chat } = EditionConfig.get();
  if (chat.enabled && !olarkScriptLoaded) {
    // Load the script and init
    /* eslint-disable */
    olarkScriptLoaded = true;
    (function (o, l, a, r, k, y) {
      if (o.olark) return;
      r = 'script';
      y = l.createElement(r);
      r = l.getElementsByTagName(r)[0];
      y.async = 1;
      y.src = `//${a}`;
      r.parentNode.insertBefore(y, r);
      y = o.olark = function () {
        k.s.push(arguments);
        k.t.push(+new Date());
      };
      y.extend = function (i, j) {
        y('extend', i, j);
      };
      y.identify = function (i) {
        y('identify', k.i = i);
      };
      y.configure = function (i, j) {
        y('configure', i, j);
        k.c[i] = j;
      };
      k = y._ = {
        s: [], t: [+new Date()], c: {}, l: a,
      };
    }(window, document, 'static.olark.com/jsclient/loader.js'));
    olark.identify('6510-773-10-7457');
    /* eslint-enable */
  }
};

export const disableOlark = () => {
  if (window.olark) {
    olark('api.box.shrink');

    // TODO: deleting the global causes errors in 3rd party script!
    //  Can we do more to disable this?!
    // setTimeout(function(){
    //   delete window.olark;
    // }, 1000)
  }
};
