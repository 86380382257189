import React from 'react';
import { eventsApi } from '../../../api';
import ModalService from '../../../libs/ModalService';
import EventShareMenu from './EventShareMenu';
import EventSearch from './EventSearch';
import EventLocaleFilter from './EventLocaleFilter';
import EventTimingFilter from './EventTimingFilter';
import EventTableHead from './EventTableHead';
import EventsLock from './EventsLock';
import EventTableBody from './EventTableBody';
import ScreenConfigPropType from './ScreenConfigPropType';
import EventShareModal from './EventShareModal';
import getSortedSearchedEvents from './getSortedSearchedEvents';
import getFormattedEvents from './getFormattedEvents';
import Analytics from '../../../libs/Analytics';
import sendSharedEventsEmail from './sendSharedEventsEmail';

const PAGE_SIZE = 50;

const propTypes = {
  screenConfig: ScreenConfigPropType.isRequired,
};

class Events extends React.Component {
  constructor(props) {
    super(props);

    const columns = [
      { label: 'Share', id: 'share', sortable: false },
      { label: 'Event', id: 'topicAndType', sortable: true },
      { label: 'Date', id: 'date', sortable: true },
      { label: `Time (${Intl.DateTimeFormat().resolvedOptions().timeZone})`, id: 'time', sortable: true },
      { label: 'Locale', id: 'locale', sortable: false },
      {},
    ];

    const { screenConfig } = props;
    const searchParams = new URLSearchParams(window.location.search);
    const secret = searchParams.get('j');
    const disablePasscode = screenConfig.resources.disable_passcode;

    this.state = {
      authenticated: disablePasscode || this.checkSecret(secret),
      authAttemptFailed: false,
      loading: true,
      allEvents: [],
      eventsToShare: [],
      columns,
      sortColumnId: 'date',
      sortDirection: 'asc',
      searchTerm: '',
      localeFilters: [],
      timingFilters: [],
      numEventsToDisplay: PAGE_SIZE,
    };
  }

  initSearch = (events) => {
    // Add a composite search field to all events
    events.forEach((e) => {
      e.search = [e.product, e.topic, e.type, e.date, e.time].join(' ').toLowerCase();
    });
  };

  componentDidMount = () => {
    const { screenConfig } = this.props;
    eventsApi.get().then(({ data }) => {
      const formattedEvents = getFormattedEvents(data.events, screenConfig);
      this.setState({
        allEvents: formattedEvents,
        loading: false,
      });
      this.initSearch(formattedEvents);
      Analytics.track('Events Displayed', {
        numberOfEvents: formattedEvents.length,
      });
    });
  };

  handleAuthChange = (event) => {
    const val = event.target.value;
    let authenticated = false;
    let authAttemptFailed = false;
    if (val.length === 4) {
      authenticated = this.checkSecret(val);
      authAttemptFailed = !authenticated;
    }
    this.setState({ authenticated, authAttemptFailed });
  };

  checkSecret = (secret) => {
    return parseInt(secret, 10) === 1984;
  };

  handleColumnHeaderClick = (columnId) => {
    const { sortColumnId, sortDirection } = this.state;

    // Default to 'asc', unless an already asc-sorted column was clicked again
    const dir = (sortColumnId === columnId && sortDirection === 'desc') ? 'asc' : 'desc';
    this.setState({
      sortDirection: dir,
      sortColumnId: columnId,
      numEventsToDisplay: PAGE_SIZE,
    });

    Analytics.track('Events Sorted', {
      sortDirection: dir,
      sortColumnId: columnId,
    });
  };

  handleSearchChange = (searchTerm) => {
    this.setState({
      searchTerm,
      numEventsToDisplay: PAGE_SIZE,
    });

    Analytics.track('Events Searched', { searchTerm });
  };

  /**
   * Update locale filter state
   *
   * @param {string[]} localeFilters the country codes of selected locales
   */
  handleLocaleFilterChange = (localeFilters) => {
    this.setState({ localeFilters });

    Analytics.track('Event Locale Filtered', { localeFilters: localeFilters.join(', ') });
  };

  handleTimingFilterChange = (timingFilters) => {
    this.setState({ timingFilters });

    Analytics.track('Event Timing Filtered', { timingFilters: timingFilters.join(', ') });
  };

  handleLoadMoreClick = () => {
    let { numEventsToDisplay } = this.state;
    numEventsToDisplay += PAGE_SIZE;
    this.setState({ numEventsToDisplay });

    Analytics.track('Load More Events Clicked', { numEventsToDisplay });
  };

  handleShareButtonClick = () => {
    const { eventsToShare } = this.state;
    const modalContent = (
      <EventShareModal
        events={eventsToShare}
        sendButtonClicked={this.sendSharedEvents}
      />
    );
    ModalService.display(modalContent, 'modal-share-event', null, true, false);

    Analytics.track('Event Share Modal Opened', {
      numberOfEvents: eventsToShare.length,
      eventIds: eventsToShare.map(e => e.id).join(', '),
    });
  };

  sendSharedEvents = (email, customMessage) => {
    const { eventsToShare } = this.state;
    const { screenConfig } = this.props;

    sendSharedEventsEmail(
      email, customMessage, eventsToShare, screenConfig.resources.email_to_bcc_when_sharing,
    ).then(() => {
      Analytics.track('Events Shared', {
        numberOfEvents: eventsToShare.length,
        eventIds: eventsToShare.map(e => e.id).join(', '),
      });
      ModalService.hide();
      this.setState({ eventsToShare: [] });
    });
  };

  handleShareCancel = () => {
    this.setState({ eventsToShare: [] });
  };

  handleShareCheckboxClick = (event) => {
    const { eventsToShare } = this.state;
    const newEventsToShare = eventsToShare.slice();
    const index = eventsToShare.indexOf(event);

    if (index === -1) {
      newEventsToShare.push(event);
    } else {
      newEventsToShare.splice(index, 1);
    }

    this.setState({
      eventsToShare: newEventsToShare,
    });
  };

  render = () => {
    const {
      searchTerm, localeFilters, timingFilters, columns, sortColumnId, sortDirection, loading,
      allEvents, eventsToShare, numEventsToDisplay, authenticated, authAttemptFailed,
    } = this.state;
    const { screenConfig } = this.props;
    if (!authenticated) {
      return (
        <section className="events">
          <EventsLock
            handleChange={this.handleAuthChange}
            attemptFailed={authAttemptFailed}
            attemptFailedMessage={screenConfig.resources.auth_attempt_failed_message}
            unlockMessage={screenConfig.resources.unlock_message}
          />
        </section>
      );
    }

    return (
      <section className="events">
        <div className="connect-events">
          <div className="connect-events-header">
            <EventSearch searchTerm={searchTerm} handleChange={this.handleSearchChange} />
            <EventTimingFilter
              selectedTimingFilters={timingFilters}
              handleChange={this.handleTimingFilterChange}
            />
            <EventLocaleFilter handleChange={this.handleLocaleFilterChange} />
          </div>
          <div className="connect-events-body">
            <div className="connect-events-body-inner">
              <table>
                <EventTableHead
                  columns={columns}
                  sortColumnId={sortColumnId}
                  sortDirection={sortDirection}
                  handleColumnHeaderClick={this.handleColumnHeaderClick}
                />
                <EventTableBody
                  loading={loading}
                  events={getSortedSearchedEvents(
                    allEvents,
                    localeFilters,
                    timingFilters,
                    searchTerm,
                    sortColumnId,
                    sortDirection,
                  )}
                  eventsToShare={eventsToShare}
                  numEventsToDisplay={numEventsToDisplay}
                  handleLoadMoreClick={this.handleLoadMoreClick}
                  handleShareCheckboxClick={this.handleShareCheckboxClick}
                  numColumns={columns.length}
                />
              </table>
            </div>
          </div>
          <EventShareMenu
            numberOfEventsToShare={eventsToShare.length}
            handleClick={this.handleShareButtonClick}
            handleCancel={this.handleShareCancel}
          />
        </div>
      </section>
    );
  };
}

Events.propTypes = propTypes;

export default Events;
