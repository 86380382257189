import * as Cookies from 'tiny-cookie';
import querystring from 'querystring';

// Abbreviated params for shorter URLs
export const LANGUAGE_CODE_OVERRIDE_PARAM = 'lang';
export const COUNTRY_CODE_OVERRIDE_PARAM = 'ctry';

/**
 * Set user's preferred locale in cookies
 * These cookies are the source of truth for user locale preferences
 *
 * @param {string | null} [countryCode] the user's preferred country code
 * @param {string | null} [languageCode] the language code
 * @param {boolean} [reload] whether to reload the page after setting cookies
  */

function set(countryCode, languageCode, reload) {
  if (countryCode) Cookies.set('preferred_country_code', countryCode);
  if (languageCode) Cookies.set('preferred_language_code', languageCode);
  if (reload) window.location.reload();
}

// The cookieconsent_country_code is set automatically via `cookie-consent.js`.  User preferences trump this.
function get() {
  const languageCode = Cookies.get('preferred_language_code')
    || Cookies.get('cookieconsent_country_code')
    || window.navigator.language;
  const countryCode = Cookies.get('preferred_country_code') || Cookies.get('cookieconsent_country_code') || null;
  return { languageCode, countryCode };
}

// If query string contains language and country information, set the locale
function checkUrlForLocaleOverride(location) {
  const queryObj = querystring.parse(location.search.substring(1));
  const ctry = queryObj[COUNTRY_CODE_OVERRIDE_PARAM];
  const lang = queryObj[LANGUAGE_CODE_OVERRIDE_PARAM];

  if (ctry && lang) {
    set(ctry, lang, false);

    // Remove locale query params once override is complete
    if (window.history.pushState) {
      const newQueryObj = {... queryObj};
      delete newQueryObj[COUNTRY_CODE_OVERRIDE_PARAM];
      delete newQueryObj[LANGUAGE_CODE_OVERRIDE_PARAM];
      const newUrlQueryString = querystring.encode(newQueryObj);

      let path = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      if (newUrlQueryString) {
        path += '?' + newUrlQueryString;
      }
      window.history.pushState({ path }, '', path);
    }
  }
}

// Piece together a locale override URL
function generateOverrideUrl(location, pathname, countryCode, languageCode) {
  const queryObj = {};
  queryObj[LANGUAGE_CODE_OVERRIDE_PARAM] = languageCode;
  queryObj[COUNTRY_CODE_OVERRIDE_PARAM] = countryCode;
  const query = querystring.encode(queryObj);

  return `${location.protocol}//${location.host}/${pathname}?${query}`;
}

export default {
  set, get, checkUrlForLocaleOverride, generateOverrideUrl,
};

