import React from 'react';
import PropTypes from 'prop-types';

/**
 * Warn users that the screen does not have content for their language
 */
const propTypes = {
  languageCode: PropTypes.string.isRequired,
};
const ScreenNoContentWarning = ({ languageCode }) => (
  <div className="screen-no-content-warning">
    <h2>{getVerbiage(languageCode)}</h2>
  </div>
);

ScreenNoContentWarning.propTypes = propTypes;

// Strange hard-coded translation because the existing paradigms don't make sense for app-wide, permanent strings.
const getVerbiage = (languageCode) => {
  switch (languageCode){
  case 'de':
    return 'Entschuldigung, es gibt keine Inhalte für diese Seite auf Deutsch';
  case 'en-US':
    return 'Sorry, there is no content for this page in US English';
  case 'es':
    return 'Lo sentimos, no hay contenido para esta página en español';
  case 'fr':
    return 'Désolé, il n\'y a pas de contenu pour cette page en français';
  case 'pt-BR':
    return 'Desculpe, não há conteúdo para esta página em português brasileiro';
  default:
    return 'Sorry, there is no content for this page in the current language';
  }
};

export default ScreenNoContentWarning;
