// Returns an object suitable for including as properties in an Analytics.track call
const getFlattenedEvent = (event) => {
  return {
    eventId: event.id,
    eventDate: event.date,
    eventType: event.type,
    eventTopic: event.topic,
    eventUrl: event.url,
  };
};

export default getFlattenedEvent;
