import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  additionalProps: PropTypes.shape({}),
};

const defaultProps = {
  className: '',
  additionalProps: {},
};

// Class required to accept ref prop for drag-and-drop
// eslint-disable-next-line react/prefer-stateless-function
class CustomScreenGridLayout extends React.Component {
  render() {
    const { className, additionalProps, children } = this.props;

    return (
      <div className={`custom-screen-grid-layout ${className || ''}`} {...additionalProps}>
        {children}
      </div>
    );
  }
}

CustomScreenGridLayout.propTypes = propTypes;
CustomScreenGridLayout.defaultProps = defaultProps;

export default CustomScreenGridLayout;
