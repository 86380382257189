import React from 'react';
import flatten from 'flat';
import ScreenConfigPropType from './ScreenConfigPropType';
import Analytics from '../../../libs/Analytics';
import ModalService from '../../../libs/ModalService';
import PdfShareMenu from './PdfShareMenu';
import PdfShareModal from './PdfShareModal';
import sendSharedResourceEmail from './sendSharedResourceEmail';

// Identical to LinkTiles with the exception of the screen-config-spec,
// which replaces instances of "link" with "pdf"
class PdfTiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = { itemsToShare: [], submitting: false };
  }

  sendButtonClicked = (emailAddress, customMessage) => {
    const { itemsToShare } = this.state;
    this.setState({ submitting: true });
    sendSharedResourceEmail(emailAddress, customMessage, itemsToShare).then(() => {
      ModalService.hide();
      this.setState({ itemsToShare: [], submitting: false });

      const itemProperties = flatten(itemsToShare);
      Analytics.track('PDFs Shared', {
        numberOfItems: itemsToShare.length,
        ...itemProperties,
      });
      setTimeout(() => {
        ModalService.display(<p>Resources shared to {emailAddress}</p>, 'success', 5000, true, true);
      }, 600);
    }, (error) => {
      ModalService.display(<p>Error sending sharing resources {error}</p>, 'error', 5000, true, true);
    });
  };

  shareCancelled = () => {
    this.setState({ itemsToShare: [] });
  };

  shareItemClicked = (pdf) => {
    const { itemsToShare } = this.state;
    let newItemsToShare = itemsToShare.slice();
    const found = itemsToShare.find(p => p.href === pdf.href);

    if (found) {
      newItemsToShare = newItemsToShare.filter(p => p.href !== pdf.href);
    } else {
      newItemsToShare.push(pdf);
    }

    this.setState({
      itemsToShare: newItemsToShare,
    });
  };

  shareSelected = () => {
    const { itemsToShare, submitting } = this.state;
    const modalContent = (
      <PdfShareModal
        items={itemsToShare}
        submitting={submitting}
        sendButtonClicked={this.sendButtonClicked}
      />
    );
    ModalService.display(modalContent, 'modal-share-event', null, true, false);

    const itemProperties = flatten(itemsToShare);
    Analytics.track('PDF Share Modal Opened', {
      numberOfItems: itemsToShare.length,
      ...itemProperties,
    });
  };

  render() {
    const { screenConfig } = this.props;
    const { itemsToShare } = this.state;
    const { resources } = screenConfig;
    let numberOfTiles = 0;
    let noTilesHavePoints = true;

    // Structure is a flat list of 15 sets of tile attributes
    const tileElems = [...Array(15).keys()].map((index) => {
      const numeral = index + 1;
      if (!resources[`tile_${numeral}_enabled`]) return null;

      const title = resources[`tile_${numeral}_title`];
      const point1 = resources[`tile_${numeral}_point_1`];
      const point2 = resources[`tile_${numeral}_point_2`];
      const point3 = resources[`tile_${numeral}_point_3`];
      const image = resources[`tile_${numeral}_image`];

      // This base component can handle "link" and "pdf" types
      const href = resources[`tile_${numeral}_link`] || resources[`tile_${numeral}_pdf`];
      numberOfTiles += 1;

      if (point1 || point2 || point3) noTilesHavePoints = false;

      const isSelectedForSharing = itemsToShare.find(p => p.href === href);

      const combinedDescription = [point1, point2, point3]
        .filter(p => p && p.length)
        .map((p) => {
          if (!p) return null;

          // If it ends in punctuation, leave it be
          if (p.slice(-1).match(/[^\w\s\n\t]/)) {
            return p;
          }

          // Add a period if no punctuation
          return `${p}.`;
        })
        .join(' ');

      return (
        <li key={title}>
          <div className={`shareable-tile ${isSelectedForSharing ? 'selected' : ''}`}>
            <div className="shareable-tile-body">
              <div className="image">
                <img alt="" src={image} />
              </div>
              {title ? <h4>{title}</h4> : null}
              <ul>
                {point1 ? <li>{point1}</li> : null}
                {point2 ? <li>{point2}</li> : null}
                {point3 ? <li>{point3}</li> : null}
              </ul>
            </div>
            <div className="shareable-tile-footer">
              <button
                type="button"
                className={`btn btn-sm shareable-tile-footer-btn ${isSelectedForSharing ? 'active' : ''}`}
                title="Select this event to share"
                onClick={() => {
                  this.shareItemClicked({
                    href, title, description: combinedDescription, image,
                  });
                }}
              >
                Share
              </button>
              <a
                className="btn btn-sm shareable-tile-footer-btn"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                style={{ borderLeft: 'none' }}
                onClick={() => {
                  Analytics.track('PDF Clicked', { href, title, image });
                }}
              >
                View
              </a>
            </div>
          </div>
        </li>
      );
    });

    let tilesClassName = 'tiles';
    if (numberOfTiles === 4) {
      tilesClassName += ' has-four-tiles';
    }
    if (noTilesHavePoints) {
      tilesClassName += ' no-tiles-have-points';
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <ul className={tilesClassName}>
            {tileElems}
          </ul>
        </div>
        <div className="bottom-actions-wrapper">
          <PdfShareMenu
            handleClick={this.shareSelected}
            numberOfItemsToShare={itemsToShare.length}
            handleCancel={this.shareCancelled}
          />
        </div>
      </div>
    );
  }
}

PdfTiles.propTypes = {
  screenConfig: ScreenConfigPropType.isRequired,
};

export default PdfTiles;
