// isDebugMode is determined by app being loaded with special hash, eg: app/foo/#debug
// debug mode can be use in _any_ environment!
const isDebugMode = window.location.hash.indexOf('debug') !== -1;

// TODO: figure this flag out in Webpackerland
const PRODUCTION = false;

// /* global PRODUCTION */
const Env = {
  isProd: PRODUCTION,
  isDev: !PRODUCTION,
  isDebugMode,
};

export default Env;
