import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {CopyToClipboard} from "react-copy-to-clipboard";

const propTypes = {
  slug: PropTypes.string.isRequired,
  devMode: PropTypes.bool.isRequired,
};

const ComponentTarget = ({ slug, devMode }) => {
  const [copied, setCopied] = useState(false);

  if (devMode) return null;

  const href = window.location.origin + window.location.pathname + '#' + slug;
  const label = copied ? '✅ Copied' : 'Copy link to component';
  return (
    <CopyToClipboard
      text={href}
      onCopy={() => {
        setCopied(true);

        window.setTimeout(() => {
          setCopied(false);
        }, 3000);
      }}
    >
      <div className="component-target">
        <button id={slug + '_button'}>
          🔗
        </button>
        <label htmlFor={slug + '_button'}>{label}</label>
      </div>
    </CopyToClipboard>
  );
};

ComponentTarget.propTypes = propTypes;
export default ComponentTarget;
