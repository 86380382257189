import React from 'react';
import PropTypes from 'prop-types';
import { TIMING_FILTER_OPTIONS } from './eventTimingOptions';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedTimingFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const EventTimingFilter = ({ handleChange, selectedTimingFilters }) => {
  const toggleTimingFilter = (option) => {
    const alreadyPresent = selectedTimingFilters.indexOf(option) !== -1;
    let filters;

    if (alreadyPresent) {
      filters = [];
    } else {
      filters = [option];
    }
    handleChange(filters);
  };

  const options = TIMING_FILTER_OPTIONS.map((option) => {
    return (
      <button
        key={option}
        type="button"
        className={selectedTimingFilters.indexOf(option) !== -1 ? 'btn active' : 'btn'}
        onClick={() => toggleTimingFilter(option)}
      >
        {option}
      </button>
    );
  });

  return (
    <div className="connect-filter">
      {options}
    </div>
  );
};

EventTimingFilter.propTypes = propTypes;

export default EventTimingFilter;
