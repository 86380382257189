import React from 'react';
import Analytics from '../../libs/Analytics';

class RouteNotFound extends React.Component {
  componentDidMount() {
    Analytics.track('Route Not Found', {
      pathname: window.location.pathname,
    });
  }

  render() {
    return (
      <div>
        <h2 id="heading">Route not found!</h2>
      </div>
    );
  }
}

export default RouteNotFound;
