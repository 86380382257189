// Simple sharing for edition config from API
let globalModalComponent = null;

const ModalService = {
  registerModalComponent: (modalComponent) => {
    if (globalModalComponent) {
      throw new Error('Global Modal Component already registered.');
    }
    globalModalComponent = modalComponent;
  },
  hide: () => {
    globalModalComponent.hide();
  },
  display: (
    contentHtmlOrReactComponent,
    classToAdd,
    autoCloseTimeout,
    clickBackdropToClose,
    clickAnywhereToClose,
    onBackdropClick,
  ) => {
    if (!globalModalComponent) {
      throw new Error('Global Modal Component not registered yet.');
    }
    globalModalComponent.display(
      contentHtmlOrReactComponent,
      classToAdd,
      autoCloseTimeout,
      clickBackdropToClose,
      clickAnywhereToClose,
      onBackdropClick,
    );
  },
};

export default ModalService;
