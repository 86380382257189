import React from 'react';
import PropTypes from 'prop-types';

const propType = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    image: PropTypes.string,
  })).isRequired,
  sendButtonClicked: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

class PdfShareModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      emailIsValid: false,
    };
  }

  emailInputChanged = (event) => {
    const emailAddress = event.target.value;
    // 99.9% validity test adapted from: https://stackoverflow.com/a/9204568
    const isValid = emailAddress.length && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
    this.setState({
      emailAddress,
      emailIsValid: isValid,
    });
  };

  messageInputChanged = (event) => {
    const customMessage = event.target.value;
    this.setState({ customMessage });
  };

  render = () => {
    const { items, sendButtonClicked, submitting } = this.props;
    const { emailAddress, emailIsValid, customMessage } = this.state;
    return (
      <form>
        <h2>Share {items.length} {items.length === 1 ? ' PDF' : ' PDFs'}</h2>
        <div className="form-group">
          <label htmlFor="pdfShareEmailInput">Email</label>
          <input
            id="pdfShareEmailInput"
            type="email"
            className="form-control"
            placeholder="mail@example.com"
            value={emailAddress}
            onChange={this.emailInputChanged}
          />
        </div>
        <div className="form-group">
          <label htmlFor="pdfShareMessage">Message (optional)</label>
          <textarea
            id="connectShareMessage"
            className="form-control"
            maxLength="300"
            onChange={this.messageInputChanged}
            value={customMessage}
          />
        </div>
        <p>
          <button
            type="button"
            className={`btn btn-block ${emailIsValid && !submitting ? '' : ' disabled'}`}
            onClick={() => { sendButtonClicked(emailAddress, customMessage); }}
          >
            Share PDFs
          </button>
        </p>
      </form>
    );
  };
}

PdfShareModal.propTypes = propType;

export default PdfShareModal;
