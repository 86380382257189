import axios from 'axios';
import baseApiUrl from './baseApiUrl';
import userLocale from '../libs/userLocale';

function get(screenId) {
  const { languageCode, countryCode } = userLocale.get();

  return axios.get(
    `${baseApiUrl}/legacy_screens/${screenId}`,
    { params: { country_code: countryCode, language_code: languageCode } },
  );
}

export default { get };
