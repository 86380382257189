import axios from 'axios';

// Milliseconds to wait between calls
const POLLING_DELAY = 1000;

// How long to poll for before giving up
const POLLING_TIMEOUT = 60 * 1000;

// Check and re-check status until it resolves or the timeout is tripped
function checkJobStatus(id, resolve, reject, timeout) {
  if (Date.now() > timeout) {
    reject('Submission timed out.');
    return;
  }

  axios({
    url: `/api/v1/marketo_post/${id}`,
    method: 'GET',
  }).then((response) => {
    switch (response.data.body.job_status) {
    case 'succeeded':
      resolve();
      break;
    case 'failed':
      reject('Submission failed.');
      break;
    default:
      setTimeout(() => checkJobStatus(id, resolve, reject, timeout), POLLING_DELAY);
    }
  }).catch(reject);
}

// Poll the job status until it is completed
function pollUntilComplete(id) {
  return new Promise((resolve, reject) => {
    checkJobStatus(id, resolve, reject, Date.now() + POLLING_TIMEOUT);
  });
}

// POST a lead submission to the Marketo endpoint
function postGetStartedForm(formId, fields) {
  return new Promise((resolve, reject) => {
    // Make the initial POST
    axios({
      url: '/api/v1/marketo_post',
      method: 'POST',
      data: {
        form_id: formId,
        fields,
      },
    })
      .then((response) => {
        const { id } = response.data.body;

        // Now that the submission has been initiated,
        // poll the status until it is completed
        pollUntilComplete(id)
          .then(() => {
            resolve();
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

export default { postGetStartedForm };
