import mailerApi from '../../../api/mailerApi';
import EditionConfig from '../../../config/EditionConfig';

function sendSharedEventsEmail(email, customMessage, ourEvents, emailToBcc) {
  const events = ourEvents.map((event) => {
    return {
      title: event.topic,
      description: `${event.product} ${event.type} Event`,
      date: event.date,
      time: event.time,
      url: event.url,
      iCalUrl: event.ical_url,
    };
  });

  const data = {
    personalizations: [{
      to: [{ email }],
      dynamic_template_data: {
        events,
        customMessage,
        editionPathname: EditionConfig.get().pathname,
      },
    }],
    from: { email: EditionConfig.get().from_email_address },
    template_id: 'd-3b3bb9302175478bb02734a7bb6eba53',
  };

  if (emailToBcc) {
    data.personalizations[0].bcc = [{ email: emailToBcc }];
  }

  return mailerApi.sendEmail(data);
}

export default sendSharedEventsEmail;
