import React from 'react';
import PropTypes from 'prop-types';
import CancelCircleIcon from '../../../../../assets/images/icons/cancel-circle.svg';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

const EventSearch = ({ handleChange, searchTerm }) => {
  return (
    <div className="search">
      <input
        id="event-search"
        type="text"
        placeholder="Search Events"
        value={searchTerm}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
      <a
        className={searchTerm.length === 0 ? 'clear hidden' : 'clear'}
        onClick={() => {
          handleChange('');
        }}
      >
        <CancelCircleIcon />
      </a>
    </div>
  );
};

EventSearch.propTypes = propTypes;

export default EventSearch;
