// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import ReactRailsUJS from 'react_ujs';

const componentRequireContext = require.context(
  // The root pack path
  'frontend',
  true,

  // Include all files in the pack except for test files
  // See: https://github.com/reactjs/react-rails/issues/758#issuecomment-578309520
  /^(?!.*__tests__\/.*$).+$/
);

ReactRailsUJS.useContext(componentRequireContext);
