import React from 'react';
import * as PropTypes from 'prop-types';
import US_STATES from '../../../constants/US_STATES';
import CA_PROVINCES from '../../../constants/CA_PROVINCES';
import { storesApi } from '../../../api';
import Console from '../../../libs/Console';
import SavedProductSelections from '../../../libs/SavedProductSelections';
import EditionConfig from '../../../config/EditionConfig';
import getProductSelectionQuestionnaireCopy from './getProductSelectionQuestionnaireCopy';
import marketoApi from '../../../api/marketoApi';
import zipCodeApi from '../../../api/zipCodeApi';
import sendJamfNowGetStartedEmail from './sendJamfNowGetStartedEmail';
import ModalService from '../../../libs/ModalService';
import GetStartedForm from './GetStartedForm';
import Analytics from '../../../libs/Analytics';
import isCanadianPostalCode from '../../../libs/isCanadianPostalCode';

let formInvalidationInProgress = false;

const propTypes = {
  industries: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  product: PropTypes.string,
  passedInFormData: PropTypes.arrayOf(PropTypes.shape({})),
  marketoFormId: PropTypes.string.isRequired,
  alwaysShowResellerEmailField: PropTypes.bool.isRequired,
  showResellerSalesDepartment: PropTypes.bool.isRequired,
  showResellerStores: PropTypes.bool.isRequired,
  showZipCodeField: PropTypes.bool.isRequired,
  leadSourceDetail: PropTypes.string.isRequired,
  jamfNowCustomerFollowupEmailEnabled: PropTypes.bool.isRequired,
  jamfNowCustomerFollowupEmailTemplateId: PropTypes.string,
  jamfNowRepFollowupEmailEnabled: PropTypes.bool.isRequired,
  jamfNowRepFollowupEmailTemplateId: PropTypes.string,
  startOver: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  editionSalesforceId: PropTypes.string.isRequired,

  // Labels
  countryLabel: PropTypes.string.isRequired,
  selectPrompt: PropTypes.string.isRequired,
  stateLabel: PropTypes.string.isRequired,
  appleStoreLabel: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  resellerFormLabel: PropTypes.string.isRequired,
  firstNameLabel: PropTypes.string.isRequired,
  lastNameLabel: PropTypes.string.isRequired,
  commentsAndQuestionsLabel: PropTypes.string.isRequired,
  customerFormLabel: PropTypes.string.isRequired,
  companyLabel: PropTypes.string.isRequired,
  numberOfAppleDevicesLabel: PropTypes.string.isRequired,
  industryLabel: PropTypes.string.isRequired,
  privacyMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  contactUsButtonLabel: PropTypes.string.isRequired,
  contactUsButtonSubmittingLabel: PropTypes.string.isRequired,
};

const defaultProps = {
  product: null,
  passedInFormData: null,
  jamfNowCustomerFollowupEmailTemplateId: '',
  jamfNowRepFollowupEmailTemplateId: '',
};

function getRepEmailForSubmission(state) {
  if (state.resellerEmail && state.resellerEmail.length) return state.resellerEmail;

  // Use store's email if email was not manually set
  const store = state.allResellerStores.find(s => s.name === state.resellerStoreName);
  return store ? store.email : '';
}

function getSavedResellerInfo() {
  return JSON.parse(localStorage.getItem('JP_ResellerInformation'));
}

function getSavedState(fieldName) {
  const savedInfo = getSavedResellerInfo();
  if (savedInfo) {
    return savedInfo[fieldName] || '';
  }
  return null;
}

// We save all of this info, but it is not always used.
// We only reinstate name and email when an Online store has been selected, for example
function saveSelections(state) {
  const info = {
    resellerStoreName: state.resellerStoreName,
    resellerCountry: state.resellerCountry,
    resellerState: state.resellerState,
    resellerEmail: state.resellerEmail,
    resellerFirstName: state.resellerFirstName,
    resellerLastName: state.resellerLastName,
  };
  localStorage.setItem('JP_ResellerInformation', JSON.stringify(info));
}

// Append the list of answered questions from the product selection modal
function getInitialCommentsAndQuestions(state, props) {
  const productSelected = `\n// Product Selected: ${props.product} `;
  const productSelectionQuestionnaire = getProductSelectionQuestionnaireCopy(
    props.passedInFormData,
  );
  return state.commentsAndQuestions + productSelected + productSelectionQuestionnaire;
}

// Here we map our state to the official Marketo field names
function getMarketoFormData(state, props, leadSourceDetail) {
  return {
    // Reseller information
    Reseller_Contact_Name__c: `${state.resellerFirstName} ${state.resellerLastName}`,
    Reseller_Company__c: state.resellerStoreName,
    Reseller_Contact_Email__c  : getRepEmailForSubmission(state),

    // Customer information
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
    company: state.company,
    industry: state.industry,
    state: state.state,
    country: state.country,
    postalCode: state.zipCode,

    // Metadata / Data
    numberofAppleDevices: state.numberOfAppleDevices,
    Internal_Sales_Notes__c: getInitialCommentsAndQuestions(state, props),
    Lead_Source_Detail__c: leadSourceDetail,
  };
}

class GetStartedFormContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      alwaysShowResellerEmailField, showResellerSalesDepartment, industries, showZipCodeField,
    } = props;

    this.state = {
      loading: true,
      submitting: false,
      submitAttempted: false,
      showResellerEmailField: alwaysShowResellerEmailField,
      showResellerSalesDepartment,
      showZipCodeField,

      // Reseller fields
      allResellerStores: [],
      resellerStoreOptions: [],
      resellerCountryOptions: [],
      resellerStateOptions: [],
      resellerStoreName: '',
      resellerCountry: '',
      resellerState: '',
      resellerFirstName: '',
      resellerLastName: '',
      resellerEmail: '',
      resellerSalesDepartment: '',
      commentsAndQuestions: '',

      // Customer fields
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      numberOfAppleDevices: '',
      stateOptions: [],
      state: '',
      zipCode: '',
      countryOptions: [],
      country: '',
      industryOptions: industries,
      industry: '',
    };

    this.originalState = { ...this.state };
  }

  componentDidMount() {
    this.getStoresAndInit();
    const savedResellerInfo = JSON.parse(localStorage.getItem('JP_ResellerInformation'));
    Analytics.track('Get Started Form Displayed', savedResellerInfo);
  }

  getStoresAndInit() {
    const { showResellerStores } = this.props;

    // If we don't need to show stores skip fetching them
    if (!showResellerStores) {
      return this.init([]);
    }

    // Get store list, then build the form
    return storesApi.get().then(({ data }) => {
      this.init(data.stores);
    });
  }

  // If states are supported for this country, add them
  getStatesForCountry = (country) => {
    let stateOptions = [];
    if (country === 'us' || country === 'United States') {
      stateOptions = [...US_STATES];
    } else if (country === 'ca' || country === 'Canada') {
      stateOptions = [...CA_PROVINCES];
    }
    return stateOptions;
  };

  // Get store options that are relevant for the country and state presented
  getResellerStoreOptionsForCountryAndState = (country, state, allStores) => {
    let storeOptions = [];
    if (state && state.length) {
      storeOptions = allStores.filter((store) => {
        return store.state === state;
      });
    } else if (country && country.length) {
      storeOptions = allStores.filter((store) => {
        return store.country_code === country;
      });
    }
    storeOptions = storeOptions.sort((a, b) => b.name - a.name);
    return storeOptions;
  };

  getSavedDeviceCount = () => {
    // Don't default to  0 – it should be empty so users are required to fill it out
    let totalDevices = '';
    const storedPricing = SavedProductSelections.get(false);
    if (storedPricing) {
      ['macos', 'ios'].forEach((productId) => {
        if (storedPricing[productId] && storedPricing[productId].active) {
          totalDevices += parseInt(storedPricing[productId].quantity, 10);
        }
      });
    }
    return totalDevices;
  };

  getDefaultIndustry = () => {
    const { passedInFormData, industries } = this.props;
    let industry = '';
    if (passedInFormData) {
      const passedInIndustry = passedInFormData.find(f => f.id === 'industry').value;
      if (typeof passedInIndustry === 'string' && passedInIndustry.length
        && industries.find(i => i.value === passedInIndustry)) {
        industry = passedInIndustry;
      }
    }
    return industry;
  };

  getDefaultDeviceCount = () => {
    const { passedInFormData } = this.props;
    let deviceCount = this.getSavedDeviceCount();
    if (passedInFormData) {
      const passedInDeviceCount = passedInFormData.find(f => f.id === 'numberOfAppleDevices').value;
      if (passedInDeviceCount && passedInDeviceCount.length) {
        deviceCount = passedInDeviceCount;
      }
    }
    return deviceCount;
  };

  displayZipCodeLookupError = (zipCode) => {
    const content = (
      <div>
        <h3>Invalid Zip Code</h3>
        <p>{zipCode} is not a valid zip code</p>
        <p>Please update and try again.</p>
      </div>
    );

    ModalService.display(content, 'error', null, true);
  };

  // Works for all fields and dropdowns
  handleFieldChange = (fieldName, fieldValue) => {
    let newState = { ...this.state };
    newState[fieldName] = fieldValue;

    // Apply business rules before committing the change!
    newState = this.applyBusinessRules(newState, this.state);

    // Do always, for simplicity
    saveSelections(newState);

    this.setState(newState);
  };

  applyBusinessRules = (newState, oldState) => {
    const { alwaysShowResellerEmailField } = this.props;
    const resultState = { ...newState };
    const relevantFields = ['country', 'resellerCountry', 'resellerState', 'resellerStoreName', 'resellerEmail'];
    const relevantFieldChanged = relevantFields.find((fieldName) => {
      return resultState[fieldName] !== oldState[fieldName];
    });

    if (relevantFieldChanged) {
      // 0. Clear state fields if appropriate
      if (!['us', 'ca'].includes(resultState.country)) {
        resultState.state = '';
      }
      if (!['us', 'ca'].includes(resultState.resellerCountry)) {
        resultState.resellerState = '';
      }

      // 1. Get states for current country
      resultState.stateOptions = this.getStatesForCountry(resultState.country);
      resultState.resellerStateOptions = this.getStatesForCountry(resultState.resellerCountry);

      // 2. Get filtered stores
      resultState.resellerStoreOptions = this.getResellerStoreOptionsForCountryAndState(
        resultState.resellerCountry, resultState.resellerState, resultState.allResellerStores,
      );

      // 3. Show email field for Online stores, or if configured to always be visible
      const resellerStore = resultState.allResellerStores.find((s) => {
        return s.name === resultState.resellerStoreName;
      });
      const isOnline = resellerStore && resellerStore.online;
      resultState.showResellerEmailField = alwaysShowResellerEmailField || isOnline;
    }
    return resultState;
  };

  // We need the stores before we can populate critical parts of the form
  init = (stores) => {
    const allResellerStores = [...stores];
    let resellerStoreName = getSavedState('resellerStoreName');
    if (allResellerStores.length === 1) {
      resellerStoreName = allResellerStores[0].name;
    }

    // Display all countries
    const countryOptions = EditionConfig.get().supported_countries;
    let country = getSavedState('resellerCountry') || EditionConfig.get().current_country.code;
    if (countryOptions.length === 1) {
      const [onlyCountry] = countryOptions;
      country = onlyCountry;
    }

    const resellerStoreOptions = allResellerStores;

    // Note: resellerStoreOptions is set by applyBusinessRules()

    let newState = {
      loading: false,
      allResellerStores,
      resellerStoreOptions,
      resellerCountryOptions: [...countryOptions],
      resellerStoreName,
      resellerCountry: country, // Already checked for saved state!
      resellerState: getSavedState('resellerState') || '',
      resellerFirstName: getSavedState('resellerFirstName') || '',
      resellerLastName: getSavedState('resellerLastName') || '',
      resellerEmail: getSavedState('resellerEmail') || '',

      countryOptions: [...countryOptions],
      country,

      numberOfAppleDevices: this.getDefaultDeviceCount(),
      industry: this.getDefaultIndustry(),
    };

    newState = this.applyBusinessRules(newState, this.state);

    this.setState(newState);
  };

  // Fired if HTML5 validation fails (submit event is not triggered)
  handleInvalidForm = () => {
    this.setState({ submitAttempted: true });

    if (!formInvalidationInProgress) {
      const invalidFields = [...document.querySelectorAll('.form-control:invalid')];
      const errorMap = invalidFields.reduce((acc, input) => {
        acc[input.id] = input.validationMessage;
        return acc;
      }, {});
      Analytics.track('Get Started Form Invalid', errorMap);

      // Fired many times – once for each field. Only trigger one Analytics call
      formInvalidationInProgress = true;
      window.setTimeout(() => {
        formInvalidationInProgress = false;
      });
    }
  };

  sendEmailAndPostToMarketo = () => {
    const {
      product, marketoFormId, successMessage, leadSourceDetail, jamfNowCustomerFollowupEmailEnabled,
      jamfNowRepFollowupEmailEnabled, jamfNowCustomerFollowupEmailTemplateId,
      jamfNowRepFollowupEmailTemplateId,
    } = this.props;
    const {
      email, firstName, industry, resellerFirstName,
    } = this.state;
    const formData = getMarketoFormData(this.state, this.props, leadSourceDetail);

    const formPromise = marketoApi.postGetStartedForm(marketoFormId, formData);
    const isJamfNow = product === 'Jamf Now';

    const shouldSendCustomerEmail = isJamfNow && jamfNowCustomerFollowupEmailEnabled;
    const customerEmailPromise = shouldSendCustomerEmail
      ? sendJamfNowGetStartedEmail(
        email, firstName, industry, jamfNowCustomerFollowupEmailTemplateId,
      )
      : Promise.resolve();

    const shouldSendRepEmail = isJamfNow && jamfNowRepFollowupEmailEnabled;
    const repEmailPromise = shouldSendRepEmail
      ? sendJamfNowGetStartedEmail(
        getRepEmailForSubmission(this.state), resellerFirstName, industry,
        jamfNowRepFollowupEmailTemplateId,
      )
      : Promise.resolve();

    this.setState({ submitting: true, submitAttempted: true });

    // Joint handler when all requests complete
    Promise.all([formPromise, customerEmailPromise, repEmailPromise])
      .then((responses) => {
        const customerEmailSent = responses[1] === 'success';
        this.displaySuccess(customerEmailSent, successMessage, product, formData);
      })
      .catch((error) => {
        const msg = typeof error === 'string' ? error : error.message;
        this.displayError(msg, product, formData);
      });
  };

  displaySuccess = (customerEmailSent, successMessage, product, data) => {
    const modalContent = (
      <div>
        <p>{successMessage}</p>
        {customerEmailSent ? <p>Welcome email sent.</p> : null}
      </div>
    );
    ModalService.display(<div>{modalContent}</div>, 'success', 5000, true, true);

    // Reset fields
    this.setState(this.originalState);
    this.getStoresAndInit();

    Analytics.track('Get Started Form Submission Succeeded', {
      product,
      emailSent: customerEmailSent,
      ...data,
    });
  };

  displayError = (msg, product, data) => {
    ModalService.display(<div>An error occurred: <br />{msg}</div>, 'error',
      null,
      true,
      true);
    Console.error(`Errors submitting form or sending email: ${msg}`);

    // Clear the submitting state
    this.setState({ submitting: false });

    Analytics.track('Get Started Form Submission Failed', {
      error: msg,
      product,
      ...data,
    });
  };

  handleFormSubmit = (event) => {
    // If relevant, lookup the state using the zipcode before submitting
    const { zipCode } = this.state;
    if (zipCode.length && !isCanadianPostalCode(zipCode)) {
      zipCodeApi.getStateCodeForZipCode(zipCode)
        .then(({ data: state }) => {
          this.setState({ state, country: 'United States' }, () => {
            this.sendEmailAndPostToMarketo();
          });
        })
        .catch((() => this.displayZipCodeLookupError(zipCode)));
    } else {
      this.sendEmailAndPostToMarketo();
    }

    // Don't do traditional page refresh on submit
    event.preventDefault();
    return false;
  };

  render() {
    const {
      product, startOver, countryLabel, selectPrompt, stateLabel, appleStoreLabel,
      emailLabel, resellerFormLabel, firstNameLabel, lastNameLabel, customerFormLabel, companyLabel,
      numberOfAppleDevicesLabel, industryLabel, commentsAndQuestionsLabel, privacyMessage,
      contactUsButtonSubmittingLabel, contactUsButtonLabel,
    } = this.props;
    const {
      submitAttempted, loading, submitting, showResellerEmailField, showResellerSalesDepartment,
      resellerStoreOptions, resellerStateOptions, resellerCountryOptions,
      resellerStoreName, resellerState, resellerCountry, resellerFirstName, resellerLastName,
      resellerEmail, resellerSalesDepartment, firstName, lastName, email, company,
      numberOfAppleDevices, stateOptions, state, zipCode, countryOptions, country, industryOptions,
      industry, commentsAndQuestions, zipCodeLookupError,
    } = this.state;

    // If no product specified, use a generic label
    const productLabel = product || 'Jamf';

    return (
      <div className="get-started-form-container">
        <h1>Get Started with {productLabel}</h1>
        {startOver
          ? <a className="back" onClick={startOver}>Choose again...</a>
          : null}
        <GetStartedForm
          submitAttempted={submitAttempted}
          loading={loading}
          submitting={submitting}
          showResellerEmailField={showResellerEmailField}
          showResellerSalesDepartment={showResellerSalesDepartment}
          showZipCodeField={country === 'us'}
          resellerSalesDepartment={resellerSalesDepartment}
          resellerStoreOptions={resellerStoreOptions}
          resellerStateOptions={resellerStateOptions}
          resellerCountryOptions={resellerCountryOptions}
          resellerStoreName={resellerStoreName}
          resellerState={resellerState}
          resellerCountry={resellerCountry}
          resellerFirstName={resellerFirstName}
          resellerLastName={resellerLastName}
          resellerEmail={resellerEmail}
          firstName={firstName}
          lastName={lastName}
          email={email}
          company={company}
          numberOfAppleDevices={numberOfAppleDevices}
          stateOptions={stateOptions}
          state={state}
          zipCode={zipCode}
          countryOptions={countryOptions}
          country={country}
          industryOptions={industryOptions}
          industry={industry}
          commentsAndQuestions={commentsAndQuestions}
          handleFieldChange={this.handleFieldChange}
          handleFormSubmit={this.handleFormSubmit}
          handleInvalidForm={this.handleInvalidForm}
          countryLabel={countryLabel}
          selectPrompt={selectPrompt}
          stateLabel={stateLabel}
          appleStoreLabel={appleStoreLabel}
          emailLabel={emailLabel}
          resellerFormLabel={resellerFormLabel}
          firstNameLabel={firstNameLabel}
          lastNameLabel={lastNameLabel}
          commentsAndQuestionsLabel={commentsAndQuestionsLabel}
          customerFormLabel={customerFormLabel}
          companyLabel={companyLabel}
          numberOfAppleDevicesLabel={numberOfAppleDevicesLabel}
          industryLabel={industryLabel}
          privacyMessage={privacyMessage}
          contactUsButtonLabel={contactUsButtonLabel}
          contactUsButtonSubmittingLabel={contactUsButtonSubmittingLabel}
          zipCodeLookupError={zipCodeLookupError}
        />
      </div>
    );
  }
}

GetStartedFormContainer.propTypes = propTypes;
GetStartedFormContainer.defaultProps = defaultProps;

export default GetStartedFormContainer;
