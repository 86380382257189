import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  handleColumnHeaderClick: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    sortable: PropTypes.bool,
  })).isRequired,
  sortColumnId: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};

const EventTableHead = ({
  columns, sortColumnId, sortDirection, handleColumnHeaderClick,
}) => {
  const headers = columns.map((col) => {
    let headerClassName = '';
    let sortClassName = '';
    let sortIcon = null;
    let onClick = null;
    if (col.sortable) {
      sortClassName = 'icon-sort';
      if (col.id === sortColumnId) {
        headerClassName = 'sorted';
        sortClassName += ` ${sortDirection}`;
      }
      sortIcon = <span className={sortClassName} />;
      onClick = () => {
        return col.id && handleColumnHeaderClick(col.id);
      };
    }
    return (
      <th key={col.id + col.label} className={headerClassName} onClick={onClick}>
        {col.label}
        {sortIcon}
      </th>
    );
  });
  return <thead><tr>{headers}</tr></thead>;
};

EventTableHead.propTypes = propTypes;

export default EventTableHead;
