// 1. Replace underscores 2. TitleCase
// eg jamf_now --> Jamf Now
const getProductSelectionQuestionnaireCopy = (questionnaireData) => {
  let result = '';
  const answeredFields = [];
  if (questionnaireData) {
    questionnaireData.forEach((field) => {
      let { value } = field;
      let wasAnswered = false;

      if (Array.isArray(value)) {
        wasAnswered = value.length > 0;
        value = value.join(', ');
      } else if (typeof value === 'string') {
        wasAnswered = value.length > 0;
      }

      if (wasAnswered) answeredFields.push(`${field.label}: ${value}`);
    });

    if (answeredFields.length > 0) {
      result += ` \n// Product Selection Questionnaire // ${answeredFields.join(' \n// ')}`;
    }
  }
  return result;
};

export default getProductSelectionQuestionnaireCopy;
